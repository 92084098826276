import {
  EmbeddedScene,
  QueryVariable,
  SceneAppPage,
  SceneControlsSpacer,
  SceneFlexItem,
  SceneFlexItemLike,
  SceneFlexLayout,
  SceneReactObject,
  SceneTimePicker,
  SceneTimeRange,
  SceneVariableSet,
  TextBoxVariable,
  VariableValueSelectors,
} from '@grafana/scenes';
import PrometheusPicker from 'components/PrometheusPicker';
import React from 'react';
import { ScenesCustomParams } from 'types';
import { config } from '@grafana/runtime';
import { DataSourceRef } from '@grafana/schema';
import {
  alertsTableQueryRunner,
  clusterAlertsQueryRunner,
  namespaceAlertsQueryRunner,
  severityAlertsQueryRunner,
} from './queryRunners';
import { tableConfig, timeseriesConfig } from './panelConfig';
import { VariableHide } from '@grafana/data';
import {
  addDatasourceSyncHandler,
  addTimeRangeHandler,
  addVizPanelMenuHandler,
  getRefreshPicker,
} from 'helpers/scenes';
import { getNoDataFlowingAppScene } from 'components/NoDataFlowing/NoDataFlowing';
import TitleRow, { SubTitle } from '../TitleRow/TitleRow';
import PastDataAlert from 'components/PastDataAlert/PastDataAlert';
import logo from 'img/k8s.png';

const getVariables = (datasource: DataSourceRef, prometheusName: string, lokiName: string) => {
  const datasourceVar = new TextBoxVariable({
    name: 'datasource',
    value: prometheusName,
    hide: VariableHide.hideVariable,
  });

  const lokiVar = new TextBoxVariable({
    name: 'loki',
    value: lokiName,
    hide: VariableHide.hideVariable,
  });

  const cluster = new QueryVariable({
    name: 'cluster',
    query: 'label_values(kube_node_info, cluster)',
    includeAll: true,
    defaultToAll: true,
    datasource,
  });
  const severity = new QueryVariable({
    name: 'severity',
    query: 'label_values(ALERTS{alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"$cluster"},severity)',
    includeAll: true,
    defaultToAll: true,
    datasource,
  });
  const namespace = new QueryVariable({
    name: 'namespace',
    query: 'label_values(kube_namespace_status_phase{cluster=~"$cluster"},namespace)',
    includeAll: true,
    defaultToAll: true,
    datasource,
  });
  const cachePodNames = new QueryVariable({
    name: 'cachePodNames',
    query:
      'ALERTS{alertstate="firing", alertname=~"(Kube.*|CPUThrottlingHigh)", cluster=~"(${cluster:pipe})", severity=~"(${severity:pipe})", namespace=~"(${namespace:pipe})", pod!=""}',
    includeAll: true,
    defaultToAll: true,
    skipUrlSync: true,
    datasource,
    regex: '/.*pod="(?<value>[^"]*)".*/',
    hide: VariableHide.hideVariable,
  });

  const variableSet = new SceneVariableSet({
    variables: [datasourceVar, lokiVar, cluster, severity, namespace, cachePodNames],
  });

  return variableSet;
};

const topRow = (dsId: string) => {
  return [
    {
      runner: clusterAlertsQueryRunner(dsId),
      title: 'Firing alerts by cluster',
      config: timeseriesConfig,
    },
    {
      runner: namespaceAlertsQueryRunner(dsId),
      title: 'Firing alerts by namespace',
      config: timeseriesConfig,
    },
    {
      runner: severityAlertsQueryRunner(dsId),
      title: 'Alert severity',
      config: timeseriesConfig,
    },
  ].map(({ config, ...panel }) => {
    return new SceneFlexItem({
      height: 350,
      body: addVizPanelMenuHandler(config(panel).build()),
    });
  });
};

const tableRow = (datasource: DataSourceRef) => {
  return new SceneFlexItem({
    body: addVizPanelMenuHandler(
      tableConfig({
        runner: alertsTableQueryRunner(datasource),
        title: 'Firing Alerts at ${__to:date:YYYY-MM-DD HH:mm:ss}',
      }).build()
    ),
  });
};

export function getAlertScene({
  id,
  prometheusName,
  lokiName,
  dataFlowing,
  datasource,
  relativeTimeRange,
}: ScenesCustomParams) {
  const dsId = String(config.datasources[prometheusName]?.id);
  const sceneVariables: SceneVariableSet = getVariables(datasource, prometheusName, lokiName);

  let sceneChildren: SceneFlexItemLike[] = [
    new SceneFlexLayout({
      direction: 'row',
      height: 350,
      children: topRow(dsId),
    }),
    new SceneFlexLayout({
      direction: 'row',
      children: [tableRow(config.datasources[prometheusName])],
    }),
  ];

  const sceneTimeRange = new SceneTimeRange(relativeTimeRange);
  addTimeRangeHandler(sceneTimeRange);

  if (!dataFlowing) {
    sceneChildren = [getNoDataFlowingAppScene()];
  }

  const scene = new SceneAppPage({
    title: 'Alerts',
    url: `/a/${id}/alerts`,
    renderTitle: (title: string) => (
      <TitleRow
        title={title}
        withNavigation
        page="alerts"
        subtitle={
          <SubTitle
            text="For more on filtering, using runbooks, and managing alerts, visit the"
            url="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/respond-to-alerts/#start-from-alerts-page"
          />
        }
      />
    ),
    titleImg: logo,
    subTitle: <PastDataAlert sceneTimeRange={sceneTimeRange} />,
    $timeRange: sceneTimeRange,
    controls: [
      new SceneReactObject({
        reactNode: React.createElement(PrometheusPicker),
      }),
      new SceneControlsSpacer(),
      new SceneTimePicker({ isOnCanvas: true }),
      new SceneControlsSpacer(),
      getRefreshPicker(),
    ],
    getScene: () =>
      new EmbeddedScene({
        $behaviors: [],
        $variables: sceneVariables,
        controls: [new VariableValueSelectors({})],
        body: new SceneFlexLayout({
          direction: 'column',
          children: sceneChildren,
        }),
      }),
  });

  addDatasourceSyncHandler(scene, prometheusName, lokiName);

  return scene;
}
