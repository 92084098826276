import { DataFrame } from '@grafana/data';
import { SceneComponentProps, SceneObjectBase, SceneObjectState, sceneGraph } from '@grafana/scenes';
import { Icon, LoadingPlaceholder, useStyles2 } from '@grafana/ui';
import { statusOffline, statusOnline, statusUnknown } from 'img';
import React from 'react';
import { configStatusStyles } from './ClusterStatus.styles';
import ExploreButton from 'components/ExploreButton/ExploreButton';
import { DataQueryWithExpr } from 'types';

interface StatusState extends SceneObjectState {
  title: string;
  subtitle: string;
  status: 'Online' | 'Offline' | 'Loading' | 'Multiple';
  version?: boolean;
  isMultiple?: boolean;
  exploreUrl: string;
  docUrl: string;
}

const getStatusValue = (data: DataFrame[] | undefined) => {
  if (data && data.length === 0) {
    return 'Offline';
  }
  const value = data?.[0]?.fields?.[1]?.values?.get(0);
  return value !== 0 ? 'Online' : 'Offline';
};

const getPossibleMultiple = (data: DataFrame[] | undefined) => {
  if (!data || data.length === 0) {
    return 'Offline';
  }

  const l = data?.[0]?.fields?.length || 0;
  const value = data?.[0]?.fields?.[l - 1]?.values?.get(0);
  if (value === 0) {
    return 'Offline';
  } else if (value === 1) {
    return 'Online';
  } else {
    return 'Multiple';
  }
};

const getVersionValue = (data: DataFrame[] | undefined) => {
  const l = data?.[0]?.fields?.length || 0;
  const version = data?.[0]?.fields?.[l - 1]?.labels?.version;
  return version;
};

export class StatusPanel extends SceneObjectBase<StatusState> {
  constructor(state?: Partial<StatusState>) {
    super({
      status: 'Loading',
      title: '',
      subtitle: '',
      version: false,
      exploreUrl: '/explore',
      docUrl: '',
      ...state,
    });

    this.initHeaderActionsSync();
  }

  static Component = StatusPanelRenderer;

  private initHeaderActionsSync() {
    this.addActivationHandler(() => {
      const data = sceneGraph.getData(this);

      const unsubscribable = data.subscribeToState((newDataState) => {
        let queries = (newDataState.data?.request?.targets ?? []) as DataQueryWithExpr[];
        queries = queries.map?.((q) => ({
          ...q,
          expr: sceneGraph.interpolate(this, q.expr),
        }));

        const datasource = queries.find((query) => !!query.datasource?.uid)?.datasource?.uid;

        if (datasource) {
          const { from, to } = sceneGraph.getTimeRange(this).state;

          const left = encodeURIComponent(
            JSON.stringify({
              datasource,
              queries,
              range: {
                from,
                to,
              },
            })
          );

          this.setState({ exploreUrl: `/explore?left=${left}` });
        }
      });

      return () => unsubscribable.unsubscribe?.();
    });
  }
}

interface IconComponentProps {
  status: string;
}

const IconComponent = ({ status }: IconComponentProps) => {
  switch (status) {
    case 'Online':
      return <img src={statusOnline} />;
    case 'Offline':
      return <img src={statusOffline} />;
    case 'Multiple':
      return <img src={statusUnknown} />;
    case undefined:
      return <img src={statusUnknown} />;
    default:
      return <img src={statusOnline} />;
  }
};

function StatusPanelRenderer({ model }: SceneComponentProps<StatusPanel>) {
  const data = sceneGraph.getData(model).useState();
  const styles = useStyles2(configStatusStyles);
  const { title, version, subtitle, isMultiple, exploreUrl, docUrl } = model.useState();

  let displayValue: string;
  const statusValue = getStatusValue(data.data?.series);
  const versionValue = getVersionValue(data.data?.series) || statusValue;
  const multipleValue = getPossibleMultiple(data.data?.series);

  switch (true) {
    case isMultiple && version:
      displayValue = multipleValue === 'Online' ? versionValue : multipleValue;
      break;
    case version:
      displayValue = versionValue;
      break;
    case isMultiple:
      displayValue = multipleValue;
      break;
    default:
      displayValue = statusValue;
      break;
  }

  return (
    <div className={styles.gridItem}>
      <div className={styles.gridItemTop}>
        <div className={styles.centerAlign}>
          <h5 className={styles.marginBottom}>{title}</h5>
          <div className={styles.marginLeft}>
            <ExploreButton href={exploreUrl} label="" />
          </div>
        </div>
        <div className={styles.gridItemTopRight}>
          <div className={styles.marginRight}>
            {!data.data?.series ? (
              <LoadingPlaceholder text="Loading..." className={styles.marginBottom} />
            ) : (
              displayValue
            )}
          </div>
          <IconComponent status={displayValue} />
        </div>
      </div>
      <span className={styles.subtitleText}>
        {subtitle}{' '}
        {docUrl && (
          <a className={styles.link} href={docUrl} target="_blank" rel="noreferrer">
            Docs <Icon size="sm" name="external-link-alt" />
          </a>
        )}
      </span>
    </div>
  );
}
