import React from 'react';
import { DeploymentConfig } from '../../../../api/config';
import { useHistory } from 'react-router-dom';
import { PLUGIN_ROOT_URL } from '../../../../constants';
import { encodeUrlString } from 'helpers/helpers';
import useIntegrationStore from 'store/integration';
import { shallow } from 'zustand/shallow';
import { Queries } from 'queries';
import useDatasourceStore from 'store/datasource';
import { Button } from '@grafana/ui';

type Props = {
  deploymentConfigValues: DeploymentConfig;
};

const ConfigFinal = ({ deploymentConfigValues }: Props) => {
  const [isDataFlowing] = useIntegrationStore((state) => [state.isDataFlowing], shallow);
  const [prometheusName] = useDatasourceStore((state) => [state.prometheusName], shallow);
  const history = useHistory();

  // Re-check if data is flowing when config is done
  const handleDoneConfigClick = (target: 'home' | 'cluster') => {
    isDataFlowing(Queries.IsDataFlowing, prometheusName, true);
    if (target === 'home') {
      history.push(`${PLUGIN_ROOT_URL}/home`);
    } else {
      history.push(
        `${PLUGIN_ROOT_URL}/configuration/metrics-status?var-cluster=${encodeUrlString(deploymentConfigValues.cluster)}`
      );
    }
  };

  return (
    <>
      <h3>Explore your data</h3>
      <div>
        <Button
          onClick={() => handleDoneConfigClick('home')}
          variant="primary"
          type="button"
          style={{ marginRight: 10 }}
        >
          Go to homepage
        </Button>
        <Button onClick={() => handleDoneConfigClick('cluster')} variant="secondary" type="button">
          See cluster status
        </Button>
      </div>
    </>
  );
};

export default React.memo(ConfigFinal);
