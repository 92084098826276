import { DeploymentConfig } from '../api/config';
import { HostedDataDetails } from '../api/accessPolicyToken';
import { stringify } from 'yaml';
import { ExternalService, HelmChartValues } from './helmChartStructure';
import { Platforms } from 'enums';

const INSTRUCTIONS_START = (releaseName: string, namespace: string) =>
  `helm repo add grafana https://grafana.github.io/helm-charts &&
  helm repo update &&
  helm upgrade --install --atomic --timeout 300s ${releaseName} grafana/k8s-monitoring \\
    --namespace "${namespace}" --create-namespace --values - <<EOF
`;

const INSTRUCTIONS_END = 'EOF';
export const buildHelmChartInstructions = (
  dc: DeploymentConfig,
  accessPolicyToken: string,
  platform: Platforms,
  hostedDetails?: HostedDataDetails
): string => {
  const values = buildHelmChartValues(dc, accessPolicyToken, platform, hostedDetails);
  return INSTRUCTIONS_START(dc.releaseName, dc.namespace) + stringify(values) + INSTRUCTIONS_END;
};

export const buildHelmChartValues = (
  dc: DeploymentConfig,
  accessPolicyToken: string,
  platform: Platforms,
  hostedDetails?: HostedDataDetails
): HelmChartValues => {
  // Default structures
  const prometheus: ExternalService = {
    host: hostedDetails?.hmInstancePromUrl || 'METRICS_HOST',
    basicAuth: {
      username: `${hostedDetails?.hmInstancePromId || 'METRICS_USERNAME'}`,
      password: accessPolicyToken,
    },
  };
  const loki: ExternalService = {
    host: hostedDetails?.hlInstanceUrl || 'LOGS_HOST',
    basicAuth: {
      username: `${hostedDetails?.hlInstanceId || 'LOGS_USERNAME'}`,
      password: accessPolicyToken,
    },
  };
  const tempo: ExternalService = {
    host: hostedDetails?.htInstanceUrl ? `${hostedDetails?.htInstanceUrl}:443` : 'TEMPO_HOST',
    basicAuth: {
      username: `${hostedDetails?.htInstanceId || 'TEMPO_USERNAME'}`,
      password: accessPolicyToken,
    },
  };

  const values: HelmChartValues = {
    cluster: {
      name: dc.cluster,
    },
    externalServices: {
      prometheus: dc.features.metrics ? prometheus : undefined,
      loki: dc.features.podLogs || dc.features.clusterEvents ? loki : undefined,
      tempo: dc.features.otelReceivers || dc.features.zipkinReceiver ? tempo : undefined,
    },
    metrics: {
      enabled: dc.features.metrics,
      alloy: {
        metricsTuning: {
          useIntegrationAllowList: dc.features.metrics,
        },
      },
      cost: {
        enabled: dc.features.costMetrics,
      },
      kepler: {
        enabled: dc.features.energyMetrics,
      },
      'node-exporter': {
        enabled: dc.features.metrics,
      },
      beyla: dc.features.metrics && dc.features.autoInstrumentation ? { enabled: true } : undefined,
    },
    logs: {
      enabled: dc.features.podLogs || dc.features.clusterEvents,
      pod_logs: {
        enabled: dc.features.podLogs,
      },
      cluster_events: {
        enabled: dc.features.clusterEvents,
      },
    },
    traces: {
      enabled: dc.features.otelReceivers || dc.features.zipkinReceiver,
    },
    receivers: {
      grpc: { enabled: dc.features.otelReceivers },
      http: { enabled: dc.features.otelReceivers },
      zipkin: { enabled: dc.features.zipkinReceiver },
      grafanaCloudMetrics: { enabled: dc.features.hostMetricsGeneration },
    },
    opencost: {
      enabled: dc.features.costMetrics,
      opencost: dc.features.costMetrics
        ? {
            exporter: {
              defaultClusterId: dc.cluster,
            },
            prometheus: {
              external: {
                url: (hostedDetails?.hmInstancePromUrl || 'METRICS_HOST') + '/api/prom',
              },
            },
          }
        : undefined,
    },
    'kube-state-metrics': {
      enabled: dc.features.metrics,
    },
    'prometheus-node-exporter': {
      enabled: dc.features.metrics,
    },
    'prometheus-operator-crds': {
      enabled: dc.features.metrics,
    },
    kepler: {
      enabled: dc.features.energyMetrics,
    },
    alloy: {},
    'alloy-events': {},
    'alloy-logs': {},
    beyla: dc.features.autoInstrumentation ? { enabled: true } : undefined,
  };

  switch (platform) {
    case Platforms.Autopilot:
      // Disable Node Exporter
      values.metrics['node-exporter'].enabled = false;
      values['prometheus-node-exporter'].enabled = false;
      break;

    case Platforms.Fargate:
      // Disable Node Exporter
      values.metrics['node-exporter'].enabled = false;
      values['prometheus-node-exporter'].enabled = false;

      if (!dc.features.podLogs) {
        break;
      }
      values.logs.pod_logs.gatherMethod = 'api';
      values['alloy-logs'] = {
        alloy: {
          clustering: {
            enabled: true,
          },
          mounts: {
            varlog: false,
          },
        },
        controller: {
          replicas: 2,
          type: 'deployment',
        },
      };
      break;

    case Platforms.IBMCloud:
      if (!dc.features.podLogs) {
        break;
      }

      // Add extra mount for /var/data
      values['alloy-logs'] = {
        alloy: {
          mounts: {
            extra: [
              {
                name: 'vardata',
                mountPath: '/var/data',
              },
            ],
          },
        },
        controller: {
          volumes: {
            extra: [
              {
                name: 'vardata',
                hostPath: {
                  path: '/var/data',
                },
              },
            ],
          },
        },
      };
      break;

    case Platforms.Openshift:
      values.cluster!.platform = Platforms.Openshift;

      values.metrics['kube-state-metrics'] = { service: { port: 'https-main', isTLS: true } };
      values.metrics['node-exporter'].labelMatchers = {
        'app.kubernetes.io/name': 'node-exporter',
      };

      values.metrics['node-exporter'].service = { isTLS: true };
      values['kube-state-metrics'].enabled = false;
      values['prometheus-node-exporter'].enabled = false;
      values['prometheus-operator-crds'].enabled = false;

      values['alloy'] = {
        alloy: {
          securityContext: {
            allowPrivilegeEscalation: false,
            capabilities: {
              drop: ['ALL'],
              add: [
                'CHOWN',
                'DAC_OVERRIDE',
                'FOWNER',
                'FSETID',
                'KILL',
                'SETGID',
                'SETUID',
                'SETPCAP',
                'NET_BIND_SERVICE',
                'NET_RAW',
                'SYS_CHROOT',
                'MKNOD',
                'AUDIT_WRITE',
                'SETFCAP',
              ],
            },
            seccompProfile: {
              type: 'RuntimeDefault',
            },
          },
        },
      };
      values['alloy-events'] = {
        alloy: {
          securityContext: {
            allowPrivilegeEscalation: false,
            capabilities: {
              drop: ['ALL'],
              add: [
                'CHOWN',
                'DAC_OVERRIDE',
                'FOWNER',
                'FSETID',
                'KILL',
                'SETGID',
                'SETUID',
                'SETPCAP',
                'NET_BIND_SERVICE',
                'NET_RAW',
                'SYS_CHROOT',
                'MKNOD',
                'AUDIT_WRITE',
                'SETFCAP',
              ],
            },
            seccompProfile: {
              type: 'RuntimeDefault',
            },
          },
        },
      };

      values['alloy-logs'] = {
        alloy: {
          securityContext: {
            allowPrivilegeEscalation: false,
            capabilities: {
              drop: ['ALL'],
              add: [
                'CHOWN',
                'DAC_OVERRIDE',
                'FOWNER',
                'FSETID',
                'KILL',
                'SETGID',
                'SETUID',
                'SETPCAP',
                'NET_BIND_SERVICE',
                'NET_RAW',
                'SYS_CHROOT',
                'MKNOD',
                'AUDIT_WRITE',
                'SETFCAP',
              ],
            },
            privileged: false,
            runAsUser: 0,
          },
        },
        global: {
          podSecurityContext: {
            seLinuxOptions: {
              type: 'spc_t',
            },
          },
        },
      };
      break;

    case Platforms.AzureAKS:
      values['alloy'].controller = { podAnnotations: { 'kubernetes.azure.com/set-kube-service-host-fqdn': 'true' } };
      values['alloy-events'].controller = {
        podAnnotations: { 'kubernetes.azure.com/set-kube-service-host-fqdn': 'true' },
      };
      values['alloy-logs'].controller = {
        podAnnotations: { 'kubernetes.azure.com/set-kube-service-host-fqdn': 'true' },
      };
      values['kube-state-metrics'].podAnnotations = { 'kubernetes.azure.com/set-kube-service-host-fqdn': 'true' };
      values.opencost.podAnnotations = { 'kubernetes.azure.com/set-kube-service-host-fqdn': 'true' };
      break;
  }

  return values;
};
