import { useState, useEffect, useMemo } from 'react';
import useDatasourceStore from 'store/datasource';
import { prometheusSelector } from 'store/selectors/datasource';
import { Range } from '../types';
import useSWR from 'swr';
import { buildRangeQueryOptions, buildRangeResponse, fetcher } from './useDataFrame';
import useTimeRangeStore from 'store/timeRange';
import { BackendSrvRequest, config } from '@grafana/runtime';

type UseQueriesParams = {
  queries: MetricQuery[];
  range: Range;
  step: string;
  refresh: boolean;
  maxDataPoints: number;
  convertToRedableMetrics: boolean;
  firstLoadDependencies?: string[];
};

function useQueries({
  queries,
  range,
  step,
  refresh = true,
  maxDataPoints = 100,
  convertToRedableMetrics = false,
  firstLoadDependencies,
}: UseQueriesParams) {
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [data, setData] = useState<any>([]);
  const [errors, setErrors] = useState<any>([]);
  const [hasDateChanged, setIsDataRefreshing, refreshInterval] = useTimeRangeStore((state) => [
    state.hasDateChanged,
    state.setIsDataRefreshing,
    state.refreshInterval,
  ]);
  const prometheusName = useDatasourceStore(prometheusSelector);
  const options = useMemo(() => {
    if (hasDateChanged) {
      return buildRangeQueryOptions(
        prometheusName,
        queries?.map?.(({ query }) => query),
        range,
        step,
        maxDataPoints
      );
    } else {
      return buildInstantQueryOptions(prometheusName, queries, range);
    }
  }, [prometheusName, queries, range, step, maxDataPoints, hasDateChanged]);

  const {
    data: response,
    isLoading,
    isValidating,
    error,
  } = useSWR(options, fetcher, {
    refreshInterval: !refresh ? undefined : refreshInterval,
    revalidateOnFocus: false,
    refreshWhenHidden: false,
    revalidateIfStale: false,
    revalidateOnReconnect: false,
    errorRetryCount: 0,
  });

  useEffect(() => {
    setFirstLoad(true);
  }, [firstLoadDependencies]);

  useEffect(() => {
    if (response) {
      const { errors, success } = hasDateChanged
        ? buildRangeResponse(response, convertToRedableMetrics)
        : buildInstantResponse(response);

      setErrors(errors);
      setData(success);
      setFirstLoad(false);
    }
  }, [response, error, convertToRedableMetrics, hasDateChanged]);

  useEffect(() => {
    setIsDataRefreshing(isValidating);
  }, [isValidating, setIsDataRefreshing]);

  return { loading: isLoading, validating: isValidating, data, error: errors, firstLoad };
}

type QueryError = {
  error: string;
  errorType: string;
  message: string;
  status: string;
};

export function buildInstantResponse(response: any) {
  const errors: QueryError[] = [];
  const success: unknown[] = [];
  let hasErrors = false;

  response?.forEach?.((d: any) => {
    errors.push(d.status === 'rejected' ? d?.reason?.data : {});
    success.push(d.status === 'fulfilled' ? d?.value?.data?.data?.result : {});

    if (d.status === 'rejected') {
      hasErrors = true;
    }
  });

  return { success, errors, hasErrors };
}

export type MetricQuery = {
  query: string;
  range?: Range;
  step?: string;
};

export const buildInstantQueryOptions = (
  prometheusName: string,
  metricQueries: MetricQuery[],
  overrideRange?: Range
) => {
  const url = config.datasources[prometheusName]?.url;
  const allQueryOptions: BackendSrvRequest[] = [];

  metricQueries?.forEach?.(({ query, range, step }: MetricQuery) => {
    const endpoint = step ? '/api/v1/query_range' : '/api/v1/query';

    const q = {
      query,
      instant: true,
      range: false,
      start: overrideRange?.from || range?.from,
      end: overrideRange?.to || range?.to,
    };

    const options: BackendSrvRequest = {
      url: `${url}${endpoint}`,
      method: 'POST',
      headers: { ['Content-Type']: 'application/x-www-form-urlencoded' },
      data: q,
    };

    allQueryOptions.push(options);
  });

  return allQueryOptions;
};

export default useQueries;
