import {
  EmbeddedScene,
  QueryVariable,
  SceneFlexItem,
  SceneFlexLayout,
  SceneTimeRange,
  SceneVariableSet,
} from '@grafana/scenes';
import { DataSourceRef } from '@grafana/schema';

import {
  addTimeRangeHandler,
  addVizPanelMenuHandler,
  getGenericQueryRunner,
  query_result,
} from '../../../helpers/scenes';
import { graphsConfigWithLabel } from '../PodDetailOptimization/panelConfig';
import { SceneQueries } from 'queries';
import { ScenesCustomParams } from 'types';
import { allocationRow, idleRow, setsBoxRow } from '../GenericDetailOptimization/scene';
import { VariableHide, VariableRefresh } from '@grafana/data';
import { getExtensionDetailsOptimizationControls } from 'components/Extensions/ObjectDetailExtension';
import { getNetworkScene } from '../Network/Network';

export function getVariables(
  datasource: DataSourceRef,
  cluster?: string,
  namespace?: string,
  workload?: string,
  workloadType?: string
) {
  const podNames = new QueryVariable({
    name: 'podNames',
    query: query_result(SceneQueries.WorkloadDetail.PodNames(cluster, namespace, workload, workloadType)),
    includeAll: true,
    defaultToAll: true,
    datasource,
    skipUrlSync: true,
    regex: '/.*pod="(?<value>[^"]*)".*/', // extract pod label value
    refresh: VariableRefresh.onTimeRangeChanged,
    hide: VariableHide.hideVariable,
  });

  const numContainers = new QueryVariable({
    name: 'numContainers',
    query: query_result(SceneQueries.WorkloadDetail.NumContainers(cluster, namespace)),
    datasource,
    skipUrlSync: true,
  });

  const cpuCapacity = new QueryVariable({
    name: 'cpuCapacityUpperLimit',
    query: query_result(SceneQueries.WorkloadDetail.CpuRequests(cluster, namespace)),
    datasource,
    skipUrlSync: true,
  });

  const memCapacity = new QueryVariable({
    name: 'memCapacityUpperLimit',
    query: query_result(SceneQueries.WorkloadDetail.MemoryRequests(cluster, namespace)),
    datasource,
    skipUrlSync: true,
  });

  const variableSet = new SceneVariableSet({
    variables: [podNames, numContainers, cpuCapacity, memCapacity],
  });

  return variableSet;
}

export const firstRow = (datasource: DataSourceRef, cluster?: string, namespace?: string) => {
  return new SceneFlexLayout({
    children: [
      {
        refIdPrefix: 'cpu',
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.WorkloadDetail.CpuLimits(cluster, namespace),
          {
            instant: false,
            interval: '',
            range: true,
            refId: 'cpuLimits',
            legendFormat: 'Sum of container CPU limits',
            intervalMs: '1m',
            maxDataPoints: 1718,
          },
          [
            {
              expr: SceneQueries.WorkloadDetail.CpuAllocation(cluster, namespace),
              instant: false,
              interval: '',
              range: true,
              refId: 'cpuAllocation',
              legendFormat: 'Sum of container CPU allocation',
            },
            {
              expr: SceneQueries.WorkloadDetail.CpuRequests(cluster, namespace),
              legendFormat: 'Sum of container CPU requests',
              refId: 'cpuRequests',
              interval: '',
              instant: false,
              range: true,
            },
            {
              expr: SceneQueries.WorkloadDetail.CpuUsage(cluster, namespace),
              instant: false,
              interval: '',
              range: true,
              refId: 'cpuUsage',
              legendFormat: 'Sum of container CPU usage',
            },
          ]
        ),
        title: 'Workload CPU',
        unit: 'cores',
        predictable: {
          query: SceneQueries.WorkloadDetail.CpuUsage(cluster, namespace),
          button: 'Predict CPU Usage',
          title: 'CPU Usage Prediction Model',
          name: 'Workload CPU usage',
          metric: 'container_cpu_usage_seconds_total',
          predictableVariableName: 'cpuCapacityUpperLimit',
          axisLabel: 'CPU usage (cores)',
        },
      },
      {
        refIdPrefix: 'mem',
        runner: getGenericQueryRunner(
          datasource,
          SceneQueries.WorkloadDetail.MemoryLimits(cluster, namespace),
          {
            instant: false,
            interval: '',
            range: true,
            refId: 'memLimits',
            legendFormat: 'Sum of container memory limits',
            intervalMs: '1m',
            maxDataPoints: 1718,
          },
          [
            {
              expr: SceneQueries.WorkloadDetail.MemoryAllocation(cluster, namespace),
              instant: false,
              interval: '',
              range: true,
              refId: 'memAllocation',
              legendFormat: 'Sum of container memory allocation',
            },
            {
              expr: SceneQueries.WorkloadDetail.MemoryRequests(cluster, namespace),
              instant: false,
              interval: '',
              range: true,
              legendFormat: 'Sum of container memory requests',
              refId: 'memRequests',
            },
            {
              expr: SceneQueries.WorkloadDetail.MemoryUsage(cluster, namespace),
              instant: false,
              interval: '',
              range: true,
              refId: 'memUsage',
              legendFormat: 'Sum of container memory usage',
            },
          ]
        ),
        title: 'Workload Memory',
        unit: 'bytes',
        predictable: {
          query: SceneQueries.WorkloadDetail.MemoryUsage(cluster, namespace),
          button: 'Predict memory usage',
          title: 'Memory Usage Prediction Model',
          name: 'Workload memory usage',
          metric: 'container_memory_rss',
          predictableVariableName: 'memCapacityUpperLimit',
          axisLabel: 'Memory usage (bytes)',
        },
      },
    ].map((panel) => {
      return new SceneFlexItem({
        height: 400,
        body: addVizPanelMenuHandler(graphsConfigWithLabel(panel.refIdPrefix, panel).build(), [], panel.predictable),
      });
    }),
  });
};

export function getWorkloadDetailOptimization({
  datasource,
  cluster,
  namespace,
  workload,
  workloadType,
  relativeTimeRange,
  onTimeRangeChange,
  withPickers,
  prometheusName,
  lokiName,
  isEmbeddedExtension,
}: ScenesCustomParams) {
  const sceneTimeRange = new SceneTimeRange(relativeTimeRange);
  addTimeRangeHandler(sceneTimeRange, onTimeRangeChange);

  const queries = {
    cpuRequests: SceneQueries.WorkloadDetail.CpuRequestsText(cluster, namespace),
    cpuLimits: SceneQueries.WorkloadDetail.CpuLimitsText(cluster, namespace),
    memoryRequests: SceneQueries.WorkloadDetail.MemoryRequestsText(cluster, namespace),
    memoryLimits: SceneQueries.WorkloadDetail.MemoryLimitsText(cluster, namespace),
    cpuAllocation: SceneQueries.WorkloadDetail.CostCpuAllocation(cluster, namespace),
    memoryAllocation: SceneQueries.WorkloadDetail.CostMemoryAllocation(cluster, namespace),
    cpuIdle: SceneQueries.WorkloadDetail.CostCpuIdle(cluster, namespace),
    memoryIdle: SceneQueries.WorkloadDetail.CostMemoryIdle(cluster, namespace),
  };

  const urlParams = `?from=${relativeTimeRange?.from}&to=${relativeTimeRange?.to}&promName=${prometheusName}&lokiName=${lokiName}`;
  const urlPath = `/namespace/${cluster}/${namespace}/${workloadType}/${workload}${urlParams}`;

  const children = [
    firstRow(datasource, cluster, namespace),
    setsBoxRow(datasource, queries),
    new SceneFlexLayout({
      direction: 'row',
      children: [allocationRow(datasource, queries), idleRow(datasource, queries)],
    }),
  ];

  if (isEmbeddedExtension) {
    children.push(
      getNetworkScene({
        type: 'workload',
        cluster: cluster || '',
        namespace,
        workload,
        workloadType,
        datasource,
        relativeTimeRange,
        lokiName,
        prometheusName,
      })
    );
  }

  return new EmbeddedScene({
    $variables: getVariables(datasource, cluster, namespace, workload, workloadType),
    $timeRange: sceneTimeRange,
    controls: getExtensionDetailsOptimizationControls({ withPickers, relativeTimeRange, urlPath }),
    body: new SceneFlexLayout({
      direction: 'column',
      children: [...children],
    }),
  });
}
