import { InlineField, InlineSwitch, Input, useStyles2 } from '@grafana/ui';
import React, { useCallback, useState } from 'react';
import getStyles from '../Config.styles';
import { DeploymentConfig } from '../../../../api/config';
import OpenCostConfigurationNote from './OpenCostConfig';
import PlatformSelector from './PlatformSelector/PlatformSelector';
import { aks, autopilot, fargate, iks, k8s, openshift } from 'img';
import { Platforms } from 'enums';
import PlatformAlert from './PlatformAlert';
import { cx } from '@emotion/css';

interface Props {
  deploymentConfigValues: DeploymentConfig;
  onSetFeature: (feature: string, state: boolean) => void;
  onTextReplaceChange: (fieldType: string) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  setPlatform: (platform: Platforms) => void;
  platform: Platforms;
}

const ConfigClusterFeatures = ({
  deploymentConfigValues,
  onSetFeature,
  onTextReplaceChange,
  setPlatform,
  platform,
}: Props) => {
  const styles = useStyles2(getStyles);
  const [validNamespace, setValidNamespace] = useState(true);

  const onFeatureChange = useCallback(
    (type: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const state = e.currentTarget.checked;
      onSetFeature(type, state);
    },
    [onSetFeature]
  );

  const validateNamespace = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valid = e.target.value.match(new RegExp(e.target.pattern)) != null;
    setValidNamespace(valid);
  };

  return (
    <>
      <h3>Select features and enter cluster information</h3>
      <div className={styles.textReplaceWrapper}>
        <span className={styles.textReplaceLabel}>Cluster name:</span>
        <Input
          value={deploymentConfigValues.cluster}
          onChange={onTextReplaceChange('cluster')}
          className={styles.textReplaceField}
        />
      </div>
      <div className={styles.textReplaceWrapper}>
        <span className={styles.textReplaceLabel}>Namespace:</span>
        <div>
          <Input
            value={deploymentConfigValues.namespace}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onTextReplaceChange('namespace')(e);
              validateNamespace(e);
            }}
            className={cx(styles.textReplaceField, { [styles.invalidNamespace]: !validNamespace })}
            pattern={'^[a-z0-9]([a-z0-9-]*[a-z0-9])?$'}
          />
          {!validNamespace && (
            <span className={styles.invalidNamespaceMessage}>
              Namespace is invalid. Must be lowercase, alphanumeric and can contain dashes (except at start or end).
            </span>
          )}
        </div>
      </div>
      <div className={styles.platformWrapper}>
        <PlatformSelector
          img={k8s}
          name="Kubernetes"
          isSelected={platform === Platforms.Kubernetes}
          subtitle="EKS on EC2, GKE, etc"
          onClick={() => {
            setPlatform(Platforms.Kubernetes);
          }}
        />
        <PlatformSelector
          img={aks}
          name="Azure AKS"
          isSelected={platform === Platforms.AzureAKS}
          onClick={() => {
            setPlatform(Platforms.AzureAKS);
          }}
        />
        <PlatformSelector
          img={autopilot}
          name="GKE Autopilot"
          isSelected={platform === Platforms.Autopilot}
          onClick={() => {
            setPlatform(Platforms.Autopilot);
          }}
        />
        <PlatformSelector
          img={fargate}
          name="EKS on Fargate"
          isSelected={platform === Platforms.Fargate}
          onClick={() => {
            setPlatform(Platforms.Fargate);
          }}
        />
        <PlatformSelector
          img={iks}
          name="IBM Cloud"
          isSelected={platform === Platforms.IBMCloud}
          onClick={() => {
            setPlatform(Platforms.IBMCloud);
          }}
        />
        <PlatformSelector
          img={openshift}
          name="Openshift"
          isSelected={platform === Platforms.Openshift}
          onClick={() => {
            setPlatform(Platforms.Openshift);
          }}
        />
      </div>
      <div>
        <PlatformAlert platform={platform} />
      </div>
      <div className={styles.switchWrapper}>
        <div className={styles.switchHalf}>
          <h2>Kubernetes Cluster Monitoring</h2>
          <InlineField
            tooltip={
              'If enabled, Kubernetes cluster infrastructure metrics will be scraped and sent to Grafana Cloud Prometheus.'
            }
            label={'Metrics'}
            className={styles.switch}
          >
            <InlineSwitch
              className={styles.switchButton}
              onChange={onFeatureChange('metrics')}
              value={deploymentConfigValues.features.metrics}
            />
          </InlineField>
          <InlineField
            tooltip={'If enabled, cost metrics will be scraped and sent to Grafana Cloud Prometheus.'}
            label={'Cost Metrics'}
            className={styles.switch}
          >
            <InlineSwitch
              className={styles.switchButton}
              onChange={onFeatureChange('costMetrics')}
              value={deploymentConfigValues.features.costMetrics}
            />
          </InlineField>
          <InlineField
            tooltip={'If enabled, energy metrics will be scraped and sent to Grafana Cloud Prometheus.'}
            label={'Energy Metrics'}
            className={styles.switch}
          >
            <InlineSwitch
              className={styles.switchButton}
              onChange={onFeatureChange('energyMetrics')}
              value={deploymentConfigValues.features.energyMetrics}
            />
          </InlineField>
          <InlineField
            tooltip={'If enabled, Kubernetes cluster events will be captured and sent to Grafana Cloud Loki.'}
            label={'Cluster Events'}
            className={styles.switch}
          >
            <InlineSwitch
              className={styles.switchButton}
              onChange={onFeatureChange('clusterEvents')}
              value={deploymentConfigValues.features.clusterEvents}
            />
          </InlineField>
        </div>
        <div className={styles.switchHalf}>
          <h2>Containerized Application Monitoring</h2>
          <InlineField
            tooltip={'If enabled, pod logs will be captured and sent to Grafana Cloud Loki.'}
            label={'Pod Logs'}
            className={styles.switch}
          >
            <InlineSwitch
              className={styles.switchButton}
              onChange={onFeatureChange('podLogs')}
              value={deploymentConfigValues.features.podLogs}
            />
          </InlineField>
          <InlineField
            tooltip={
              'If enabled, Grafana Alloy will be able to receive OpenTelemetry data via OTLP/gRPC and OTLP/HTTP.'
            }
            label={'OTLP Receivers'}
            className={styles.switch}
          >
            <InlineSwitch
              className={styles.switchButton}
              onChange={onFeatureChange('otelReceivers')}
              value={deploymentConfigValues.features.otelReceivers}
            />
          </InlineField>
          <InlineField
            tooltip={'If enabled, Grafana Alloy will be able to receive traces via Zipkin.'}
            label={'Zipkin Receiver'}
            className={styles.switch}
          >
            <InlineSwitch
              className={styles.switchButton}
              onChange={onFeatureChange('zipkinReceiver')}
              value={deploymentConfigValues.features.zipkinReceiver}
            />
          </InlineField>

          <InlineField
            tooltip={
              'If enabled, generates host metrics based on telemetry data. Required by Application Observability.'
            }
            label={'Grafana Application Observability'}
            className={cx(styles.switch, styles.longLabel)}
          >
            <InlineSwitch
              className={styles.switchButton}
              onChange={onFeatureChange('hostMetricsGeneration')}
              value={deploymentConfigValues.features.hostMetricsGeneration}
            />
          </InlineField>
          <InlineField
            tooltip={'If enabled, deploys Grafana Beyla to auto-instrument applications running on this Cluster..'}
            label={'Auto-instrumentation'}
            className={cx(styles.switch, styles.longLabel)}
          >
            <InlineSwitch
              className={styles.switchButton}
              onChange={onFeatureChange('autoInstrumentation')}
              value={deploymentConfigValues.features.autoInstrumentation}
            />
          </InlineField>
        </div>
      </div>
      {deploymentConfigValues.features.costMetrics && <OpenCostConfigurationNote />}
    </>
  );
};

export default React.memo(ConfigClusterFeatures);
