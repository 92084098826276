import React, { useCallback, useMemo, useState } from 'react';
import { Dropdown, Icon, IconButton, Menu, useStyles2 } from '@grafana/ui';
import getStyles from './TitleRow.styles';
import { navigationOptions } from 'components/NavigationDropdown/NavigationDropdown';
import { useHistory } from 'react-router-dom';
import { PLUGIN_ROOT_URL } from '../../../constants';
import { cx } from '@emotion/css';
import { trackRudderStackEvent } from 'hooks/useRudderstack';
import { RudderstackEvents } from 'enums';

export const SubTitle = ({ text, url }: { text: string; url: string }) => {
  const styles = useStyles2(getStyles(false));
  return (
    <p className={styles.subTitle}>
      {text}{' '}
      <a target="_blank" rel="noopener noreferrer" href={url}>
        documentation <Icon name="external-link-alt" size="sm" />
      </a>
    </p>
  );
};

const TitleRow = ({
  title,
  page = 'home',
  withNavigation = false,
  subtitle,
}: {
  title: string;
  withNavigation?: boolean;
  page?: string;
  subtitle?: React.ReactNode;
}) => {
  const styles = useStyles2(getStyles(withNavigation));
  const history = useHistory();
  const [navigationOpen, setNavigationOpen] = useState(false);

  const menu = useMemo(
    () => (
      <Menu>
        {navigationOptions.map((item) => (
          <Menu.Item
            className={cx({ [styles.selected]: page === item.value })}
            key={item.value}
            label={item.label}
            onClick={() => {
              history.push(`${PLUGIN_ROOT_URL}/${item.value}${window.location.search}`);
              trackRudderStackEvent(RudderstackEvents.TitleNavigationMenuItemClick, {
                menuItem: item.value,
              });
            }}
          />
        ))}
      </Menu>
    ),
    [history, styles.selected, page]
  );

  const onArrowClick = useCallback(() => {
    trackRudderStackEvent(RudderstackEvents.TitleNavigationMenuClick, {});
  }, []);

  const dropdownComponent = withNavigation ? (
    <div style={{ display: 'flex', alignItems: 'start', flexDirection: 'column' }}>
      <Dropdown
        overlay={menu}
        placement="bottom-end"
        offset={[0, 160]}
        onVisibleChange={(state) => setNavigationOpen(state)}
      >
        <div className={styles.wrapper}>
          <h1 className={styles.title} onClick={onArrowClick}>
            {title}
          </h1>

          <IconButton
            name={navigationOpen ? 'angle-up' : 'angle-down'}
            aria-label="Navigate"
            size="lg"
            onClick={onArrowClick}
          />
        </div>
      </Dropdown>
      {subtitle && <div>{subtitle}</div>}
    </div>
  ) : (
    <h1 className={styles.title}>{title}</h1>
  );

  return dropdownComponent;
};

export default TitleRow;
