import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react';
import { GeneralQueryResult, Namespace, NamespaceAlerts, StoredParams } from 'types';
import { ExpanderComponentProps, TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { LoadingPlaceholder, MultiSelect, Select, Tag, Tooltip, useStyles2 } from '@grafana/ui';
import { SelectableValue } from '@grafana/data';

import Table from 'components/Table/Table';
import namespaceListStyles from './NamespaceList.styles';
import NoData from 'components/NoData';
import { AssertsQueries, Queries, ResourceQueries } from 'queries';
import { RudderstackEvents, TableType } from 'enums';
import useRudderstack from 'hooks/useRudderstack';
import ExploreButton from 'components/ExploreButton/ExploreButton';
import {
  QueryCollection,
  countFiltersApplied,
  encodeUrlString,
  getNamespaceRowNameFromMetric,
  getNamespaceRowNameFromData,
} from 'helpers/helpers';
import useDatasourceStore from 'store/datasource';
import { prometheusSelector } from 'store/selectors/datasource';
import useLocalStorage from 'hooks/useLocalStorage';
import { K8S_STORAGE_KEY, NO_DATA_FLOWING_MAP } from '../../constants';
import useTimeRangeStore from 'store/timeRange';
import ExpandRow from 'components/Table/TableExpandRow';
import { TableView } from 'store/filters';
import { PluginMetaContext } from 'context/PluginMetaContext';
import { pushFaroCount } from 'helpers/faro';
import PredictionModal from 'components/PredictionModal/PredictionModal';
import NoDataFlowing from 'components/NoDataFlowing/NoDataFlowing';
import { EmbeddedScene, SceneFlexItem, SceneFlexLayout, SceneObjectBase } from '@grafana/scenes';
import CopyObjectName from 'components/Table/CopyObjectName';
import QueryParamsLink from 'components/QueryParamsLink/QueryParamsLink';
import useCostUsage from 'hooks/useCostUsage';
import { getAllColumns } from 'helpers/usageCostHelpers';
import { sortBy } from 'lodash';
import FiltersRow from 'components/FiltersRow/FiltersRow';
import ClusterLoader from 'components/ClusterLoader/ClusterLoader';
import useAssertsStore from 'store/asserts';
import { assertsColumns, selectableRowDisabled, selectableRowSelected } from 'components/Asserts/helpers';
import AssertsWorkbenchButton from 'components/Asserts/AssertsWorkbenchButton';
import _groupBy from 'lodash/groupBy';
import alertColumn from 'components/Table/alertColumn';
import useDataFrame from 'hooks/useDataFrame';

const TableActions: React.FC<ExpanderComponentProps<Namespace>> = ({ data }) => {
  const { cluster, namespace, phase } = data;

  return (
    <ExpandRow
      actions={
        <>
          <PredictionModal
            buttonText="Predict Mem Usage"
            title="Memory Usage Prediction Model"
            query={ResourceQueries.NamespaceMemory(cluster, namespace)}
            name="Namespace Memory usage"
            metric="node_namespace_pod_container:container_memory_working_set_bytes"
            hyperParamsUpperLimit={1}
          />
          <PredictionModal
            buttonText="Predict CPU Usage"
            title="CPU Usage Prediction Model"
            query={ResourceQueries.NamespaceCpuUsage(cluster, namespace)}
            name="Namespace CPU usage"
            metric="node_namespace_pod_container:container_cpu_usage_seconds_total:sum_irate"
            hyperParamsUpperLimit={1}
          />
        </>
      }
      data={[{ title: 'phase', value: String(phase) }]}
    />
  );
};

const NamespaceList = ({ defaultClusters }: { defaultClusters?: string[] }) => {
  const meta = useContext(PluginMetaContext);
  const [storedParams, setStoredParams] = useLocalStorage<StoredParams>(K8S_STORAGE_KEY);
  const styles = useStyles2(namespaceListStyles);
  const prometheusName = useDatasourceStore(prometheusSelector);
  const [range, hasDateChanged, relativeRange] = useTimeRangeStore((state) => [
    state.range,
    state.hasDateChanged,
    state.relativeRange,
  ]);
  const [assertsEnabled, selectedAssertsRows, setSelectedAssertsRows, toggledClearRows] = useAssertsStore((state) => [
    state.assertsEnabled,
    state.selectedAssertsRows,
    state.setSelectedAssertsRows,
    state.toggledClearRows,
  ]);

  const [namespaces, setNamespaces] = useState<Namespace[]>([]);
  const [namespaceFilter, setNamespaceFilter] = useState(storedParams?.namespace ?? '');
  const [clusterFilter, setClusterFilter] = useState(storedParams?.cluster || []);
  const [refreshData, setRefreshData] = useState(true);
  const trackRudderStackEvent = useRudderstack();

  const clustersPipe = clusterFilter?.join('|') || '';
  const { loading, validating, data, error, firstLoad } = useDataFrame(
    [
      Queries.Namespace('', clustersPipe),
      Queries.AlertsByNamespace('', clustersPipe),
      Queries.NamespacePhase('', clustersPipe),
      AssertsQueries.namespaceEnabled,
    ],
    { from: range.from.valueOf(), to: range.to.valueOf() },
    '', // auto step
    refreshData && !hasDateChanged,
    1,
    true,
    true
  );

  const [namespaceError] = error;
  const [namespaceData, alertData, phaseData, assertsData] = data as [
    Array<GeneralQueryResult<Namespace>>,
    Array<GeneralQueryResult<NamespaceAlerts>>,
    Array<GeneralQueryResult<Namespace>>,
    Array<GeneralQueryResult<Namespace>>
  ];

  const { location, push } = useHistory();

  const handleNamespaceFilterUpdate = (filter?: SelectableValue) => {
    setNamespaceFilter(filter?.value);
    setStoredParams({ namespace: filter?.value });
  };

  const handleClusterFilterUpdate = useCallback(
    (filters: SelectableValue[] = []) => {
      setClusterFilter(filters?.map?.((filter) => filter.value));
      setStoredParams({ cluster: filters?.map?.((filter) => filter.value) });
    },
    [setStoredParams]
  );

  const handleSelectedRowsChange = useCallback(
    ({ selectedRows }: { selectedRows: Namespace[] }) => {
      setSelectedAssertsRows(selectedRows);
    },
    [setSelectedAssertsRows]
  );

  const columns: Array<TableColumn<Namespace>> = [
    {
      name: 'Namespace',
      selector: (row: Namespace) => row.namespace,
      minWidth: '200px',
      maxWidth: '400px',
      sortable: true,
      sortFunction: (a: Namespace, b: Namespace) =>
        a.namespace.localeCompare(b.namespace, undefined, { numeric: true }),
      // eslint-disable-next-line react/display-name
      cell: (row: Namespace) => (
        <CopyObjectName
          link={`/a/${meta.id}/navigation/namespace/${encodeUrlString(row.cluster)}/${row.namespace}`}
          value={row.namespace}
        />
      ),
    },
    {
      name: 'Cluster',
      selector: (row: Namespace) => row.cluster,
      sortable: true,
      sortFunction: (a: Namespace, b: Namespace) => a?.cluster.localeCompare(b?.cluster, undefined, { numeric: true }),
      // eslint-disable-next-line react/display-name
      cell: (row: Namespace) => (
        <Tooltip content={row.cluster}>
          <QueryParamsLink
            className={styles.link}
            to={`/a/${meta.id}/navigation/cluster/${encodeUrlString(row.cluster)}`}
            label={<Tag name={row.cluster} className={styles.clusterTag} />}
          />
        </Tooltip>
      ),
      maxWidth: '300px',
    },
  ];

  useEffect(() => {
    if (!loading && namespaceData && assertsData) {
      const alertCountByName: { [key: string]: number } = {};
      const phaseByName: { [key: string]: string } = {};

      if (alertData) {
        alertData?.forEach?.(({ metric, value }: any) => {
          if (value[1] !== '0') {
            const alertCount = parseInt(value[1], 10);
            alertCountByName[`${metric.namespace}-${metric.cluster}`] = alertCount;
          }
        });
      }

      if (phaseData) {
        phaseData?.forEach?.(({ metric, value }: any) => {
          phaseByName[`${metric.namespace}`] = metric.phase;
        });
      }

      const assertsNamespaceMap = _groupBy(
        assertsData?.map?.((metric) => metric.metric),
        'namespace'
      );

      const normalized = namespaceData?.map?.((metric) => {
        const name = `${metric?.metric?.namespace}-${metric?.metric?.cluster}`;
        return {
          ...metric.metric,
          workloads: parseInt(metric?.value[1], 10),
          alertCount: alertCountByName[name],
          cluster: metric.metric.cluster || 'No data',
          phase: phaseByName[metric?.metric?.namespace] || 'No data',
          asserts_env: assertsNamespaceMap[metric?.metric?.namespace]?.[0]?.asserts_env,
          asserts_site: assertsNamespaceMap[metric?.metric?.namespace]?.[0]?.asserts_site,
        } as Namespace;
      });

      if (normalized?.length) {
        pushFaroCount('namespace-count', normalized.length);
      }

      setNamespaces(normalized);
    }
  }, [namespaceData, alertData, loading, assertsData, phaseData]);

  const filteredNamespaces = useMemo(() => {
    return namespaces
      ?.filter?.((namespace) => {
        if (namespace?.cluster === 'No data') {
          return false;
        }
        const matchesNamespace = namespace?.namespace?.includes(namespaceFilter);
        const matchesCluster = clusterFilter?.some((cluster) => namespace?.cluster?.includes(cluster));

        return (!namespaceFilter || matchesNamespace) && (!clusterFilter?.length || matchesCluster);
      })
      .sort((a, b) => {
        return b.alertCount - a.alertCount;
      });
  }, [namespaces, namespaceFilter, clusterFilter]);

  const exploreRange = {
    from: relativeRange?.from || range.from.valueOf().toString(),
    to: relativeRange?.to || range.to.valueOf().toString(),
  };

  const {
    usageLoading,
    usageCostTableData,
    costLoading,
    firstCostLoad,
    firstUsageLoad,
    tableView,
    onTableSort,
    sortColumn,
    exploreUrl,
  } = useCostUsage<Namespace>({
    type: 'namespace',
    getNameFromMetricFn: getNamespaceRowNameFromMetric,
    getNameFromDataFn: getNamespaceRowNameFromData,
    initialData: filteredNamespaces,
    usageQueries: new QueryCollection(prometheusName, exploreRange, true, ResourceQueries.NamespaceUsage, [
      clustersPipe,
    ]),
    costQueries: new QueryCollection(prometheusName, exploreRange, true, ResourceQueries.NamespaceCost, [clustersPipe]),
  });

  const { currentColumns, extraColumns } = getAllColumns<Namespace>(
    tableView === TableView.Cost,
    columns,
    [
      {
        name: 'Workloads',
        selector: (row: Namespace) => row.workloads,
        sortable: true,
      },
    ],
    [alertColumn<Namespace>({ hasDateChanged, prometheusName })],
    usageCostTableData.errors,
    usageLoading && firstUsageLoad,
    costLoading && firstCostLoad
  );

  const [namespaceOptions, clusterOptions] = useMemo(() => {
    const namespaceOpts = new Map<string, SelectableValue>();
    const clusterOpts = new Map<string, SelectableValue>();

    namespaces?.forEach?.((n) => {
      namespaceOpts.set(n.namespace, {
        label: n.namespace,
        value: n.namespace,
      });

      if (!defaultClusters?.length && (!namespaceFilter || n.namespace.includes(namespaceFilter))) {
        // Sometimes cluster value is undefined, need to make sure it exists
        if (typeof n?.cluster !== 'undefined') {
          clusterOpts.set(n.cluster, {
            label: n.cluster,
            value: n.cluster,
          });
        }
      }
    });

    if (defaultClusters?.length) {
      defaultClusters?.forEach?.((cluster) => {
        clusterOpts.set(cluster, {
          label: cluster,
          value: cluster,
        });
      });
    }

    return [sortBy([...namespaceOpts.values()], 'value'), sortBy([...clusterOpts.values()], 'value')];
  }, [namespaces, namespaceFilter, defaultClusters]);

  // Pause refresh if no data/no setup yet
  useEffect(() => {
    if (!loading && namespaceData?.length === 0) {
      setRefreshData(false);
    }
  }, [namespaceData, loading]);

  const selectableRowsCallback = useMemo(() => selectableRowSelected<Namespace>('namespace'), []);

  return (
    <NoDataFlowing message={NO_DATA_FLOWING_MAP.namespaces}>
      <div className={styles.wrapper}>
        <div className={styles.container}>
          <FiltersRow
            extraButtons={
              <ExploreButton
                href={exploreUrl}
                label="Explore namespaces"
                eventName={RudderstackEvents.ExploreNamespaces}
                size="md"
                style={{ marginRight: 0 }}
              />
            }
            visibleFilterCount={countFiltersApplied([clusterFilter, namespaceFilter])}
            visibleFilters={
              <>
                <Select
                  width={25}
                  isClearable
                  placeholder="Filter Namespace"
                  options={namespaceOptions}
                  value={namespaceFilter}
                  onChange={handleNamespaceFilterUpdate}
                />
                <MultiSelect
                  width={40}
                  isClearable
                  placeholder="Filter Clusters"
                  options={clusterOptions}
                  value={clusterFilter}
                  onChange={handleClusterFilterUpdate}
                  maxVisibleValues={3}
                />
                <AssertsWorkbenchButton
                  label="Namespaces"
                  type="Namespace"
                  nameKey="namespace"
                  relativeRange={relativeRange}
                />
              </>
            }
          />

          {(!namespaceData && validating && !namespaceError) ||
          ((loading || usageLoading || costLoading) && firstLoad) ? (
            <LoadingPlaceholder text="Loading namespaces" />
          ) : !loading && namespaceData?.length === 0 ? (
            <NoData />
          ) : (
            <Table<Namespace>
              key={sortColumn}
              withNestedHeader
              onSort={onTableSort}
              id={TableType.Cluster}
              name={TableType.Cluster}
              data={usageCostTableData.data}
              columns={[...currentColumns, ...assertsColumns<Namespace>(), ...extraColumns]}
              noDataText="No namespaces found"
              expandableRows
              expandableComponent={TableActions}
              selectable={assertsEnabled}
              onSelectedRowsChange={handleSelectedRowsChange}
              selectableRowDisabled={(row) => selectableRowDisabled<Namespace>(row, selectedAssertsRows)}
              clearSelectedRows={toggledClearRows}
              selectableRowSelected={selectableRowsCallback}
              onRowClicked={(row) => {
                trackRudderStackEvent(RudderstackEvents.NamespaceListClick, {});
                push(`${location.pathname}/${encodeUrlString(row.cluster)}/${row.namespace}`);
              }}
            />
          )}
        </div>
      </div>
    </NoDataFlowing>
  );
};

export default NamespaceList;

const NamespaceListWithDataFlowing = () => (
  <NoDataFlowing message={NO_DATA_FLOWING_MAP.namespaces}>
    <ClusterLoader>{(clusters: string[]) => <NamespaceList defaultClusters={clusters || []} />}</ClusterLoader>
  </NoDataFlowing>
);

export class NamespaceListScene extends SceneObjectBase {
  static Component = NamespaceListWithDataFlowing;
}

export function getNamespaceListAppScene() {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new NamespaceListScene({}),
        }),
      ],
    }),
  });
}
