import { Container, Pod } from 'types';
import { PodHealthStatus } from 'enums';
import { GrafanaBootConfig } from '@grafana/runtime';

export interface PodBreakdown {
  [PodHealthStatus.Healthy]: number;
  [PodHealthStatus.Unhealthy]: number;
  [PodHealthStatus.Warning]: number;
  [PodHealthStatus.Unknown]: number;
}

export const getPodhealthStatus = (pod: Pod): PodHealthStatus => {
  if (!pod) {
    return PodHealthStatus.Unknown;
  }

  if (pod.waiting_reason) {
    switch (pod.waiting_reason) {
      case 'PodInitializing':
        return PodHealthStatus.Warning;
      case 'ContainerCreating':
        return PodHealthStatus.Warning;
      case 'ImagePullBackOff':
        return PodHealthStatus.Unhealthy;
      case 'CrashLoopBackOff':
        return PodHealthStatus.Unhealthy;
      default:
        return PodHealthStatus.Unhealthy;
    }
  }

  switch (pod.phase) {
    case 'Failed':
      return PodHealthStatus.Unhealthy;
    case 'Pending':
      return PodHealthStatus.Warning;
    case 'Running':
      return PodHealthStatus.Healthy;
    case 'Succeeded':
      return PodHealthStatus.Healthy;
    case 'Unknown':
      return PodHealthStatus.Unknown;
    default:
      return PodHealthStatus.Unknown;
  }
};

export const podHealthToColor = (config: GrafanaBootConfig) => ({
  [PodHealthStatus.Healthy]: config.theme2.colors.success.main,
  [PodHealthStatus.Unhealthy]: config.theme2.colors.error.main,
  [PodHealthStatus.Warning]: config.theme2.colors.warning.main,
  [PodHealthStatus.Unknown]: config.theme2.colors.text.secondary,
});

export const podHealthToColorText = (config: GrafanaBootConfig) => ({
  [PodHealthStatus.Healthy]: config.theme2.colors.success.text,
  [PodHealthStatus.Unhealthy]: config.theme2.colors.error.text,
  [PodHealthStatus.Warning]: config.theme2.colors.warning.text,
  [PodHealthStatus.Unknown]: config.theme2.colors.text.secondary,
});

export const podHealthToText = {
  [PodHealthStatus.Healthy]: 'Running',
  [PodHealthStatus.Unhealthy]: 'Failed',
  [PodHealthStatus.Warning]: 'Pending',
  [PodHealthStatus.Unknown]: 'Unknown',
};

export const formatBytes = (bytes: number | undefined, decimals = 2): string => {
  if (bytes === undefined) {
    return 'No data';
  }

  if (bytes === 0) {
    return '0 Bytes';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const dateTimeFromTimestamp = (timestamp: string) => {
  if (!timestamp.length) {
    return 'No data';
  }
  const ts = Number(timestamp);
  if (isNaN(ts)) {
    return 'Invalid timestamp';
  }
  const date = new Date(ts * 1000);
  const d = date.toLocaleDateString('en-US');
  const t = date.toLocaleTimeString('en-US');
  if (d === 'Invalid Date') {
    return 'Invalid date';
  }
  return `${d} at ${t}`;
};

export const getContainerHealthStatus = (container: Container): PodHealthStatus => {
  if (container.waiting_reason) {
    switch (container.waiting_reason) {
      case 'PodInitializing':
        return PodHealthStatus.Warning;
      case 'ContainerCreating':
        return PodHealthStatus.Warning;
      case 'CrashLoopBackOff':
        return PodHealthStatus.Unhealthy;
      case 'ErrImagePull':
        return PodHealthStatus.Unhealthy;
      case 'ImagePullBackOff':
        return PodHealthStatus.Unhealthy;
      default:
        return PodHealthStatus.Unhealthy;
    }
  }
  return PodHealthStatus.Healthy;
};
