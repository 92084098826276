import React from 'react';
import {
  SceneAppPage,
  SceneControlsSpacer,
  SceneReactObject,
  SceneTimePicker,
  SceneTimeRange,
  SceneVariableSet,
  TextBoxVariable,
  VariableValueSelectors,
} from '@grafana/scenes';
import { getOverviewScene } from 'components/scenes/CostsOverviewScene/scene';
import { getSavingsScene } from '../CostsSavingsScene/scene';
import { Alert } from '@grafana/ui';
import { ScenesCustomParams } from 'types';
import { getNoDataFlowingAppScene } from 'components/NoDataFlowing/NoDataFlowing';
import PrometheusPicker from 'components/PrometheusPicker';
import { addDatasourceSyncHandler, addTimeRangeHandler, getRefreshPicker } from 'helpers/scenes';
import { VariableHide } from '@grafana/data';
import TitleRow, { SubTitle } from '../TitleRow/TitleRow';
import logo from 'img/k8s.png';

const getCostScene = ({
  datasource,
  prometheusName,
  lokiName,
  id,
  linkClassName,
  dataFlowing,
  relativeTimeRange,
}: ScenesCustomParams) => {
  const timeRange = new SceneTimeRange(relativeTimeRange);
  addTimeRangeHandler(timeRange);

  const scene = new SceneAppPage({
    title: 'Cost',
    renderTitle: (title: string) => (
      <TitleRow
        title={title}
        withNavigation
        page="cost"
        subtitle={
          <SubTitle
            text="For more on how to manage costs, visit the"
            url="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/manage-costs/#cost-overview"
          />
        }
      />
    ),
    titleImg: logo,
    hideFromBreadcrumbs: false,
    subTitle: (
      <Alert title="Cost monitoring" severity="info" style={{ marginBottom: 0, marginTop: 8 }}>
        Cost monitoring is a new feature. If you&apos;re missing data here, check out{' '}
        <a className={linkClassName} href={`/a/${id}/configuration`}>
          configuration
        </a>{' '}
        for how to deploy an updated configuration and start monitoring your cloud k8s costs.
      </Alert>
    ),
    url: `/a/${id}/cost`,
    $timeRange: timeRange,
    $variables: new SceneVariableSet({
      variables: [
        new TextBoxVariable({
          name: 'datasource',
          value: prometheusName,
          hide: VariableHide.hideVariable,
        }),
        new TextBoxVariable({
          name: 'loki',
          value: lokiName,
          hide: VariableHide.hideVariable,
        }),
      ],
    }),
    controls: [
      new SceneReactObject({
        reactNode: React.createElement(PrometheusPicker),
      }),
      new SceneControlsSpacer(),
      new VariableValueSelectors({}),
      new SceneControlsSpacer(),
      new SceneTimePicker({ isOnCanvas: true }),
      getRefreshPicker(),
    ],
    tabs: [
      new SceneAppPage({
        title: 'Overview',
        url: `/a/${id}/cost/overview`,
        getScene: () => (dataFlowing ? getOverviewScene({ datasource }) : getNoDataFlowingAppScene()),
      }),
      new SceneAppPage({
        title: 'Savings',
        url: `/a/${id}/cost/savings`,
        getScene: () => (dataFlowing ? getSavingsScene({ datasource }) : getNoDataFlowingAppScene()),
      }),
    ],
  });

  addDatasourceSyncHandler(scene, prometheusName, lokiName);
  return scene;
};
export default getCostScene;
