import React, { useMemo, useContext, useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert, LoadingPlaceholder, MultiSelect, useStyles2 } from '@grafana/ui';
import chunk from 'lodash/chunk';
import { RudderstackEvents, TableType } from 'enums';
import { GeneralQueryResult, Labels, NodeInfo, Pod } from 'types';
import useRudderStack from 'hooks/useRudderstack';
import { NodeQueries, ResourceQueries } from 'queries';
import useDatasourceStore from 'store/datasource';
import { lokiSelector, prometheusSelector } from 'store/selectors/datasource';
import ExploreButton from 'components/ExploreButton/ExploreButton';
import { QueryCollection, encodeUrlString, getExploreUrl } from 'helpers/helpers';
import { dateTimeFromTimestamp } from 'components/workloads/podHealthUtils';
import BreadcrumbTitle from 'components/workloads/BreadCrumbTitle';
import Table from 'components/Table/Table';
import { nodePodsColumns } from './nodePodsColumns';
import { PluginMetaContext } from 'context/PluginMetaContext';
import { getNodeInfo, getNodeLabels } from '../helpers';
import Styles from './NodeDetail.styles';
import { config } from '@grafana/runtime';
import DataList from 'components/DataList/DataList';
import useTimeRangeStore from 'store/timeRange';
import {
  EFFICIENCY_UNDER_UTILIZED_THRESHOLD,
  MAX_ITEMS_PER_LIST,
  MAX_NUMBER_OF_LISTS,
  PLUGIN_ROOT_URL,
} from '../../../constants';
import { pushFaroCount } from 'helpers/faro';
import {
  EmbeddedScene,
  SceneComponentProps,
  SceneFlexItem,
  SceneFlexLayout,
  SceneObjectBase,
  SceneObjectState,
  SceneTimeRangeState,
} from '@grafana/scenes';
import { getNodeDetailOptimization } from 'components/scenes/NodeDetailOptimization/NodeDetailOptimization';
import { TableView } from 'store/filters';
import QueryParamsLink from 'components/QueryParamsLink/QueryParamsLink';
import FiltersRow from 'components/FiltersRow/FiltersRow';
import useCostUsage from 'hooks/useCostUsage';
import { SelectableValue } from '@grafana/data';
import useAssertsStore from 'store/asserts';
import { selectableRowDisabled, selectableRowSelected } from 'components/Asserts/helpers';
import AssertsWorkbenchButton from 'components/Asserts/AssertsWorkbenchButton';
import usePods from 'hooks/usePods';
import { EntityAssertionsWidget } from 'components/EntityAssertionsWidget/EntityAssertionsWidget';
import useDataFrame from 'hooks/useDataFrame';

export const getCardInfoText = (type: string) =>
  `Under ${EFFICIENCY_UNDER_UTILIZED_THRESHOLD * 100}% usage could mean your ${type} is over-provisioned`;

const NodeDetail = ({ model }: SceneComponentProps<NodeDetailScene>) => {
  const { cluster, node } = model.useState();
  const styles = useStyles2(Styles);
  const trackRudderStackEvent = useRudderStack();
  const prometheusName = useDatasourceStore(prometheusSelector);
  const lokiName = useDatasourceStore(lokiSelector);
  const [assertsEnabled, setSelectedAssertsRows, selectedAssertsRows, toggledClearRows] = useAssertsStore((state) => [
    state.assertsEnabled,
    state.setSelectedAssertsRows,
    state.selectedAssertsRows,
    state.toggledClearRows,
  ]);
  const [podsFilter, setPodsFilter] = useState<string[]>([]);
  const { push } = useHistory();
  const meta = useContext(PluginMetaContext);
  const [range, hasDateChanged, relativeRange] = useTimeRangeStore((state) => [
    state.range,
    state.hasDateChanged,
    state.relativeRange,
  ]);

  const { pods } = usePods(cluster, undefined, undefined, node);

  const extraNodeLabels = [
    'container_runtime_version',
    'internal_ip',
    'kernel_version',
    'kubelet_version',
    'kubeproxy_version',
    'os_image',
    'system_uuid',
  ].join(',');

  const { loading, data, firstLoad } = useDataFrame(
    [
      NodeQueries.NodeInfo(cluster, node, extraNodeLabels),
      NodeQueries.NodeLabels(cluster, node),
      NodeQueries.NodeCreated(cluster, node),
    ],
    { from: range.from.valueOf(), to: range.to.valueOf() },
    '',
    !hasDateChanged,
    1,
    true,
    true
  );

  const [nodeData, labelsData, createdData] = data as [
    Array<GeneralQueryResult<NodeInfo>>,
    Array<GeneralQueryResult<Labels>>,
    Array<GeneralQueryResult<any>>
  ];

  const nodeInfo = useMemo(() => getNodeInfo(nodeData), [nodeData]);
  const labelInfo = useMemo(() => getNodeLabels(labelsData), [labelsData]);

  const [nodePodsInfo, podOpts] = useMemo(() => {
    let p = pods;

    const opts = p?.map?.(({ pod }) => ({ label: pod, value: pod })).sort((a, b) => a.label.localeCompare(b.label));
    if (p?.length) {
      pushFaroCount('node-pods-count', pods.length);
    }

    if (podsFilter?.length) {
      p = p?.filter?.(({ pod }) => {
        return podsFilter.some((item) => pod.includes(item));
      });
    }
    return [p, opts];
  }, [pods, podsFilter]);

  const createdInfo = useMemo(
    () => dateTimeFromTimestamp(createdData?.[0]?.value?.[1]?.toString() ?? '') ?? 'No data',
    [createdData]
  );

  // Lets split objects into different lists (leftList/rightList)
  const nodeListData = useMemo(() => {
    const fieldsMapping = (key: string, value: string) => {
      const rootPath = `${PLUGIN_ROOT_URL}/navigation`;
      const mapping = {
        cluster: `${rootPath}/cluster/${encodeUrlString(value)}`,
      };

      if (mapping[key as keyof typeof mapping]) {
        return <QueryParamsLink className={styles.link} to={mapping[key as keyof typeof mapping]} label={value} />;
      }

      return value;
    };

    const arrayFromObject = Object.entries(nodeInfo)?.map?.(([key, value]) => ({
      value,
      label: key,
      display: fieldsMapping(key, value as string),
    }));
    const itemsPerSubarray = Math.min(MAX_ITEMS_PER_LIST, Math.ceil(arrayFromObject.length / MAX_NUMBER_OF_LISTS));
    const finalArray = chunk(arrayFromObject, itemsPerSubarray);

    return {
      leftList: finalArray[0] || [],
      centerList: finalArray[1] || [],
      rightList: finalArray[2] || [],
    };
  }, [nodeInfo, styles.link]);

  const labelsListData = useMemo(() => {
    const arrayFromObject = Object.entries(labelInfo)?.map?.(([key, value]) => ({ value, label: key, display: value }));
    const itemsPerSubarray = Math.min(MAX_ITEMS_PER_LIST, Math.ceil(arrayFromObject.length / MAX_NUMBER_OF_LISTS));
    const finalArray = chunk(arrayFromObject, itemsPerSubarray);

    return {
      leftList: finalArray[0] || [],
      centerList: finalArray[1] || [],
      rightList: finalArray[2] || [],
    };
  }, [labelInfo]);

  const exploreRange = {
    from: relativeRange?.from || range.from.valueOf().toString(),
    to: relativeRange?.to || range.to.valueOf().toString(),
  };

  const scene = useMemo(
    () =>
      getNodeDetailOptimization({
        cluster,
        node,
        datasource: config.datasources[prometheusName],
        prometheusName,
        lokiName,
        relativeTimeRange: relativeRange as SceneTimeRangeState,
      }),
    [cluster, node, relativeRange, prometheusName, lokiName]
  );

  const {
    usageLoading,
    usageCostTableData,
    costLoading,
    firstCostLoad,
    firstUsageLoad,
    onTableSort,
    tableView,
    sortColumn,
    exploreUrl,
  } = useCostUsage<Pod>({
    type: 'pod',
    initialData: nodePodsInfo,
    usageQueries: new QueryCollection(prometheusName, exploreRange, true, ResourceQueries.NodePodUsage, [
      cluster,
      node,
    ]),
    costQueries: new QueryCollection(prometheusName, exploreRange, true, ResourceQueries.NodePodCost, [cluster, node]),
  });

  const handlePodsFilterUpdate = useCallback((filters: SelectableValue[] = []) => {
    const filterArray = filters?.map?.((filter) => filter.value);
    setPodsFilter(filterArray);
  }, []);

  const handleSelectedRowsChange = useCallback(
    ({ selectedRows }: { selectedRows: Pod[] }) => {
      setSelectedAssertsRows(selectedRows);
    },
    [setSelectedAssertsRows]
  );

  const selectableRowsCallback = useMemo(() => selectableRowSelected<Pod>('pod'), []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.titleWrapper}>
            <BreadcrumbTitle />
            <ExploreButton
              href={getExploreUrl(
                prometheusName,
                NodeQueries.NodeInfo(cluster, node, extraNodeLabels),
                exploreRange,
                true
              )}
              label="Explore node"
            />
            <EntityAssertionsWidget type="Node" name={node} env={nodeInfo.asserts_env} />
          </div>
        </div>
        {!nodePodsInfo?.length && !loading && hasDateChanged && (
          <div className={styles.sectionSpacing}>
            <Alert title="Node not found" severity="warning">
              Node doesn&apos;t exist on the selected date, try a different one.
            </Alert>
          </div>
        )}
        <div className={styles.sectionSpacing}>
          <h3 className={styles.sectionTitle}>Node information</h3>
          <div className={styles.infoWrapper}>
            {(!!nodePodsInfo?.length && !loading) || !firstLoad ? (
              <>
                <DataList list={[...nodeListData?.leftList, { label: 'create date', value: createdInfo }]} />
                <DataList list={nodeListData?.centerList} />
                <DataList list={nodeListData?.rightList} />
              </>
            ) : (
              <>{loading ? <LoadingPlaceholder text="Loading node information" /> : <span>No data available.</span>}</>
            )}
          </div>
        </div>

        <div className={styles.sectionSpacing}>
          <h3 className={styles.sectionTitle}>Node labels</h3>
          <div className={styles.infoWrapper}>
            {(!!nodePodsInfo?.length && !loading) || !firstLoad ? (
              <>
                <DataList alignValueRight list={labelsListData?.leftList} />
                <DataList alignValueRight list={labelsListData?.centerList} />
                <DataList alignValueRight list={labelsListData?.rightList} />
              </>
            ) : (
              <>{loading ? <LoadingPlaceholder text="Loading node information" /> : <span>No data available.</span>}</>
            )}
          </div>
        </div>
        <div className={styles.sectionSpacing} style={{ minHeight: 200 }}>
          <h3 className={styles.sectionTitle}>Node optimization</h3>
          <div className={styles.sceneWrapper}>
            <scene.Component model={scene} />
          </div>
        </div>
        <div className={styles.sectionSpacing}>
          <h3 className={styles.sectionTitle}>Pods</h3>
          <FiltersRow
            visibleFilters={
              <>
                <MultiSelect
                  width={25}
                  isClearable
                  placeholder="Filter by pod"
                  options={podOpts}
                  value={podsFilter}
                  onChange={handlePodsFilterUpdate}
                  maxVisibleValues={1}
                />
                <AssertsWorkbenchButton label="pods" type="Pod" nameKey="pod" relativeRange={relativeRange} />
              </>
            }
            extraButtons={<ExploreButton href={exploreUrl} label="Explore pods" size="md" style={{ marginRight: 0 }} />}
          />
          {firstLoad && (loading || usageLoading || costLoading) ? (
            <LoadingPlaceholder style={{ marginBottom: 0 }} text="Loading pod details" />
          ) : (
            <div className={styles.marginBottom}>
              {usageCostTableData && (
                <Table<Pod>
                  key={sortColumn}
                  id={TableType.Node}
                  name={`${cluster}-${node}`}
                  withNestedHeader
                  data={usageCostTableData.data}
                  columns={nodePodsColumns(
                    styles,
                    meta.id,
                    tableView === TableView.Cost,
                    usageCostTableData.errors,
                    hasDateChanged,
                    prometheusName,
                    usageLoading && firstUsageLoad,
                    costLoading && firstCostLoad
                  )}
                  noDataText="No pods found"
                  selectable={assertsEnabled}
                  onSelectedRowsChange={handleSelectedRowsChange}
                  selectableRowDisabled={(row) => selectableRowDisabled<Pod>(row, selectedAssertsRows)}
                  selectableRowSelected={selectableRowsCallback}
                  clearSelectedRows={toggledClearRows}
                  onRowClicked={(row: Pod) => {
                    trackRudderStackEvent(RudderstackEvents.PodListClick, {});
                    const podPath = `${encodeUrlString(row.cluster)}/${row.namespace}/${row.workload_type}/${
                      row.created_by_name
                    }/${row.pod}`;
                    push(`/a/${meta.id}/navigation/namespace/${podPath}`);
                  }}
                  pointerOnHover={false}
                  onSort={onTableSort}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NodeDetail;

interface NodeDetailState extends SceneObjectState {
  cluster: string;
  node: string;
}

export class NodeDetailScene extends SceneObjectBase<NodeDetailState> {
  static Component = NodeDetail;
}

export function getNodeDetailScene(cluster: string, node: string) {
  return new EmbeddedScene({
    body: new SceneFlexLayout({
      children: [
        new SceneFlexItem({
          body: new NodeDetailScene({ cluster, node }),
        }),
      ],
    }),
  });
}
