import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const dataListStyles = (isLarge: boolean, alignValueRight: boolean) => (theme: GrafanaTheme2) => ({
  list: css`
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
    overflow: hidden;

    @media (min-width: 900px) {
      flex: 1;
      width: 49%;
      max-width: 49%;
    }

    ${isLarge &&
    css`
      li > span:first-child {
        min-width: 350px;
      }
    `}
  `,
  copyButton: css`
    visibility: hidden;
  `,
  listItem: css`
    padding: 0 ${theme.spacing(3)};
    display: flex;
    border-bottom: 1px solid ${theme.colors.border.weak};
    background-color: ${theme.colors.background.secondary};

    &:hover {
      button {
        visibility: visible !important;
      }
    }

    &:first-child {
      padding-top: 4px;
    }

    &:last-child {
      padding-bottom: 4px;
    }

    > span {
      display: inline-block;
      padding: 8px;

      &:first-child {
        width: 280px;
        font-size: 15px;
        word-wrap: break-word;
        overflow-wrap: break-word;
        hyphens: auto;
      }

      &:last-child {
        width: 100%;
        display: flex;

        ${alignValueRight &&
        css`
          justify-content: flex-end;
          text-align: right;
          word-break: break-word;
        `}
      }
    }
  `,
});

export default dataListStyles;
