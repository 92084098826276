import { PredictableParam } from 'types';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

export type EfficencyFilterKeys = 'nodes' | 'clusters' | 'namespaces' | '';

type StateType = {
  predictable: PredictableParam | null | undefined;
};

type ActionType = {
  showPredictableModal: (predictable: PredictableParam | null) => void;
};

export type SortingStore = StateType & ActionType;

const initialState = {
  predictable: null,
};

export function showPredictableModal(predictable: PredictableParam | null) {
  usePredictable.setState({
    predictable,
  });
}

const usePredictable = createWithEqualityFn<SortingStore>(
  () => ({
    //state
    ...initialState,

    // actions

    showPredictableModal,
  }),
  shallow
);

export default usePredictable;
