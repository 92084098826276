import React from 'react';
import { SceneAppPage, SceneVariableSet, TextBoxVariable } from '@grafana/scenes';
import { getClusterConfigScene } from './ClusterConfig/ClusterConfigScene';
import { getClusterStatusScene } from './ClusterStatus/ClusterStatus';
import { getIntegrationsScene } from './Integrations/Integrations';
import { ScenesCustomParams } from 'types';
import { addDatasourceSyncHandler } from 'helpers/scenes';
import { getCardinalityScene } from './Cardinality/Cardinality';
import { VariableHide } from '@grafana/data';
import TitleRow, { SubTitle } from '../TitleRow/TitleRow';
import logo from 'img/k8s.png';

export const getConfigScene = ({
  id,
  datasource,
  prometheusName,
  lokiDatasource,
  lokiName,
  relativeTimeRange,
}: ScenesCustomParams) => {
  const scene = new SceneAppPage({
    title: 'Configuration',
    renderTitle: (title: string) => (
      <TitleRow
        title={title}
        withNavigation
        page="configuration"
        subtitle={
          <SubTitle
            text="For more on easy deployment using the Helm Chart, visit the"
            url="https://grafana.com/docs/grafana-cloud/monitor-infrastructure/kubernetes-monitoring/configuration/"
          />
        }
      />
    ),
    titleImg: logo,
    hideFromBreadcrumbs: false,
    url: `/a/${id}/configuration`,
    $variables: new SceneVariableSet({
      variables: [
        new TextBoxVariable({
          name: 'datasource',
          value: prometheusName,
          hide: VariableHide.hideVariable,
        }),
        new TextBoxVariable({
          name: 'loki',
          value: lokiName,
          hide: VariableHide.hideVariable,
        }),
      ],
    }),
    tabs: [
      new SceneAppPage({
        title: 'Cluster configuration',
        url: `/a/${id}/configuration/cluster-config`,
        getScene: () => getClusterConfigScene(),
      }),
      new SceneAppPage({
        title: 'Metrics status',
        url: `/a/${id}/configuration/metrics-status`,
        getScene: () =>
          getClusterStatusScene({
            datasource,
            clusterName: 'default',
            prometheusName,
            lokiDatasource: lokiDatasource!,
            lokiName: lokiName!,
            relativeTimeRange,
          }),
      }),
      new SceneAppPage({
        title: 'Integrations',
        url: `/a/${id}/configuration/integrations`,
        getScene: () => getIntegrationsScene(),
      }),
      new SceneAppPage({
        title: 'Cardinality',
        url: `/a/${id}/configuration/cardinality`,
        getScene: () =>
          getCardinalityScene({
            datasource,
            prometheusName,
            lokiName,
          }),
      }),
    ],
  });

  addDatasourceSyncHandler(scene, prometheusName, lokiName);
  return scene;
};
