import { EmbeddedScene, SceneFlexItem, SceneFlexLayout, VariableValueSelectors } from '@grafana/scenes';
import { DataSourceRef } from '@grafana/schema';
import { CostsScenesQueries } from 'queries';
import { costPercentChangeConfig, countPodsConfig, getPanelConfig, totalCostConfig } from './panelConfig';
import { addVizPanelMenuHandler, getGenericQueryRunner } from 'helpers/scenes';
import { getCostVariables } from './variables';

const totalComputeCost = (datasource: DataSourceRef) => {
  return [
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.totalCost30d60d, {
        instant: true,
        range: false,
        interval: '1h',
      }),
      title: 'Total compute cost (Prior 30-day period)',
      decimals: 2,
      fn: totalCostConfig,
    },
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.totalCostPercentChange, {
        instant: false,
        range: true,
        interval: '1h',
      }),
      title: 'Total compute cost (% change)',
      decimals: 2,
      fn: costPercentChangeConfig,
      description: '',
    },
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.totalCost0d30d, {
        instant: true,
        range: false,
        interval: '',
      }),
      fixedColor: 'semi-dark-blue',
      title: 'Total compute cost (Last 30 days)',
      fn: totalCostConfig,
      description: '',
    },
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.Next30Days.totalComputeCost, {
        instant: false,
        range: true,
        interval: '1h',
      }),
      fixedColor: 'dark-purple',
      title: 'Total compute cost (Next 30 days)',
      description: '',
      fn: totalCostConfig,
    },
  ].map(({ fn, ...panel }) => {
    return new SceneFlexItem({
      height: 150,
      body: addVizPanelMenuHandler(fn(panel).build()),
    });
  });
};

const averageCostPerPod = (datasource: DataSourceRef) => {
  return [
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.costPerPod30d60d, {
        instant: true,
        range: false,
        interval: '1h',
      }),
      title: 'Average cost per pod (Prior 30-day period)',
      decimals: 2,
      fn: totalCostConfig,
    },
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.costPerPodPercentChange, {
        instant: false,
        range: true,
        interval: '1h',
      }),
      title: 'Average cost per pod (% change)',
      decimals: 2,
      fn: costPercentChangeConfig,
      description: '',
    },
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.costPerPod0d30d, {
        instant: true,
        range: false,
        interval: '',
      }),
      fixedColor: 'semi-dark-blue',
      title: 'Average cost per pod (Last 30 days)',
      fn: totalCostConfig,
      description: '',
    },
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.Next30Days.averageCostPerPod, {
        instant: false,
        range: true,
        interval: '1h',
      }),
      fixedColor: 'dark-purple',
      title: 'Average cost per pod (Next 30 days)',
      description: '',
      fn: totalCostConfig,
    },
  ].map(({ fn, ...panel }) => {
    return new SceneFlexItem({
      height: 150,
      body: addVizPanelMenuHandler(fn(panel).build()),
    });
  });
};

const averagePodCount = (datasource: DataSourceRef) => {
  return [
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.runningPods30d60d, {
        instant: true,
        range: false,
        interval: '1h',
      }),
      title: 'Average pod count (Prior 30-day period)',
      decimals: 2,
      fn: countPodsConfig,
    },
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.runningPodsPercentChange, {
        instant: false,
        range: true,
        interval: '1h',
      }),
      title: 'Average pod count (% change)',
      decimals: 2,
      fn: costPercentChangeConfig,
      description: '',
    },
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.runningPods0d30d, {
        instant: true,
        range: false,
        interval: '',
      }),
      fixedColor: 'semi-dark-blue',
      title: 'Average pod count (Last 30 days)',
      fn: countPodsConfig,
      description: '',
    },
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.Next30Days.averagePodCount, {
        instant: false,
        range: true,
        interval: '1h',
      }),
      fixedColor: 'dark-purple',
      title: 'Average pod count (Next 30 days)',
      description: '',
      fn: countPodsConfig,
    },
  ].map(({ fn, ...panel }) => {
    return new SceneFlexItem({
      height: 150,
      body: addVizPanelMenuHandler(fn(panel).build()),
    });
  });
};

const firstRow = (datasource: DataSourceRef) => {
  return [
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.savings30Days, {
        instant: true,
        range: false,
        interval: '1h',
      }),
      title: '30 day savings projection',
      decimals: 2,
      type: 'cost',
      description: '[Instant] Cost of idle CPU cores if unallocated for next 30 days.',
    },
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.totalCost, {
        instant: false,
        range: true,
        interval: '1h',
      }),
      title: 'Total cost',
      decimals: 2,
      type: 'cost',
      description: '[Range] Total cost over the current time range.',
    },
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.unallocatedResources, {
        instant: true,
        range: false,
        interval: '',
      }),
      title: 'Unallocated Resources (PV)',
      type: 'percentage',
      description: '[Instant] Percentage of Persistent Volumes which are unclaimed.',
    },
    {
      runner: getGenericQueryRunner(
        datasource,
        CostsScenesQueries.costsVsSavingsA,
        {
          legendFormat: 'Cost',
          instant: false,
          range: true,
          interval: '1h',
        },
        [
          {
            expr: CostsScenesQueries.costsVsSavingsB,
            instant: false,
            interval: '1h',
            range: true,
            refId: 'B',
            legendFormat: 'Savings',
          },
        ]
      ),
      title: 'Cost vs savings',
      description: '[Range] Cost vs savings over the current time range.',
      type: 'graph',
    },
  ].map(({ type, ...panel }) => {
    return new SceneFlexItem({
      height: 200,
      body: addVizPanelMenuHandler(getPanelConfig(type)(panel).build()),
    });
  });
};

const secondRow = (datasource: DataSourceRef) => {
  return [
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.gcpCost, {
        interval: '1h',
        instant: false,
        range: true,
      }),
      title: 'GCP Cost',
      decimals: 2,
      type: 'cost',
      description: '[Range] Total cost over the current time range for Google Cloud Platform.',
    },
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.gcpGrowthVsTimeRange, {
        instant: true,
        range: false,
        interval: '',
      }),
      title: 'GCP growth vs. previous time range',
      type: 'percentage',
      description: '[Instant] Percentage growth for Google Cloud Platform given the current time range.',
    },
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.gcpCostByDay, {
        instant: true,
        range: false,
        interval: '',
      }),
      title: 'GCP Cost (by day)',
      decimals: 2,
      type: 'cost',
      description: '[Instant] Total cost for Google Cloud Platform if usage remains constant for the next 24 hours.',
    },
  ].map(({ type, ...panel }) => {
    return new SceneFlexItem({
      height: 150,
      body: addVizPanelMenuHandler(getPanelConfig(type)(panel).build()),
    });
  });
};

const thirdRow = (datasource: DataSourceRef) => {
  return [
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.awsCost, {
        instant: false,
        range: true,
        interval: '1h',
      }),
      title: 'AWS Cost',
      decimals: 2,
      type: 'cost',
      description: '[Range] Total cost over the current time range for Amazon Web Services.',
    },
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.awsGrowthVsTimeRange, {
        instant: true,
        range: false,
        interval: '',
      }),
      title: 'AWS growth vs. previous time range',
      type: 'percentage',
      description: '[Instant] Percentage growth for Amazon Web Services given the current time range.',
    },
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.awsCostByDay, {
        instant: true,
        range: false,
        interval: '',
      }),
      title: 'AWS Cost (by day)',
      decimals: 2,
      type: 'cost',
      description: '[Instant] Total cost for Amazon Web Services if usage remains constant for the next 24 hours.',
    },
  ].map(({ type, ...panel }) => {
    return new SceneFlexItem({
      height: 150,
      body: addVizPanelMenuHandler(getPanelConfig(type)(panel).build()),
    });
  });
};

const fourthRow = (datasource: DataSourceRef) => {
  return [
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.azureCost, {
        instant: false,
        range: true,
        interval: '1h',
      }),
      title: 'Azure Cost',
      decimals: 2,
      type: 'cost',
      description: '[Range] Total cost over the current time range for Microsoft Azure.',
    },
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.azureGrowthVsTimeRange, {
        instant: true,
        range: false,
        interval: '',
      }),
      title: 'Azure growth vs. previous time range',
      type: 'percentage',
      description: '[Instant] Percentage growth for Microsoft Azure given the current time range.',
    },
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.azureCostByDay, {
        instant: true,
        range: false,
        interval: '',
      }),
      title: 'Azure Cost (by day)',
      decimals: 2,
      type: 'cost',
      description: '[Instant] Total cost for Microsoft Azure if usage remains constant for the next 24 hours.',
    },
  ].map(({ type, ...panel }) => {
    return new SceneFlexItem({
      height: 150,
      body: addVizPanelMenuHandler(getPanelConfig(type)(panel).build()),
    });
  });
};

const fifthRow = (datasource: DataSourceRef) => {
  return [
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.othersCost, {
        instant: false,
        range: true,
        interval: '1h',
      }),
      title: 'Other Cost',
      decimals: 2,
      type: 'cost',
      description: '[Range] Total cost over the current time range for other providers.',
    },
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.othersGrowthVsTimeRange, {
        instant: true,
        range: false,
        interval: '',
      }),
      title: 'Other growth vs. previous time range',
      type: 'percentage',
      description: '[Instant] Percentage growth for other providers given the current time range.',
    },
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.othersCostByDay, {
        instant: true,
        range: false,
        interval: '',
      }),
      title: 'Other Cost (by day)',
      decimals: 2,
      type: 'cost',
      description: '[Instant] Total cost for other providers if usage remains constant for the next 24 hours.',
    },
  ].map(({ type, ...panel }) => {
    return new SceneFlexItem({
      height: 150,
      body: addVizPanelMenuHandler(getPanelConfig(type)(panel).build()),
    });
  });
};

const sixthRow = (datasource: DataSourceRef) => {
  return [
    {
      runner: getGenericQueryRunner(datasource, CostsScenesQueries.growthVsTimeRange, {
        instant: true,
        range: false,
        interval: '',
      }),
      title: 'Total growth vs. previous time range',
      type: 'percentage',
      description: '[Instant] Percentage growth given the current time range.',
      width: '100%',
    },
  ].map(({ type, ...panel }) => {
    return new SceneFlexItem({
      height: 150,
      xSizing: 'fill',
      body: addVizPanelMenuHandler(getPanelConfig(type)(panel).build()),
    });
  });
};

export function getOverviewScene({ datasource }: { datasource: DataSourceRef }) {
  return new EmbeddedScene({
    $variables: getCostVariables(datasource),
    $behaviors: [],
    controls: [new VariableValueSelectors({})],
    body: new SceneFlexLayout({
      direction: 'column',
      children: [
        ...[
          totalComputeCost(datasource),
          averageCostPerPod(datasource),
          averagePodCount(datasource),
          firstRow(datasource),
          secondRow(datasource),
          thirdRow(datasource),
          fourthRow(datasource),
          fifthRow(datasource),
          sixthRow(datasource),
        ].map(
          (row) =>
            new SceneFlexLayout({
              direction: 'row',
              children: row,
            })
        ),
      ],
    }),
  });
}
