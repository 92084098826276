import { DeploymentConfig } from '../api/config';
import { Platforms } from '../enums';
import { HostedDataDetails } from '../api/accessPolicyToken';
import { buildHelmChartValues } from './helmChartInstructionBuilder';
import { stringify } from 'yaml';

export const buildTerraformInstructions = (
  dc: DeploymentConfig,
  accessPolicyToken: string,
  platform: Platforms,
  hostedDetails?: HostedDataDetails
) => {
  const values = buildHelmChartValues(dc, accessPolicyToken, platform, hostedDetails);
  delete values.cluster;
  delete values.externalServices;
  delete values.opencost.opencost;

  const providerFile = `terraform {
  required_providers {
    helm = {
      source  = "hashicorp/helm"
      version = "2.13.1"
    }
  }
}
provider "helm" {
  kubernetes {
    # Replace this with values that provide connection to your cluster
    config_path    = "~/.kube/config"
    config_context = "${dc.cluster}-context"
  }
}`;

  let k8sMonFile = `resource "helm_release" "grafana-k8s-monitoring" {
  name             = "grafana-k8s-monitoring"
  repository       = "https://grafana.github.io/helm-charts"
  chart            = "k8s-monitoring"
  namespace        = var.namespace
  create_namespace = true
  atomic           = true
  timeout          = 300

  values = [file("\${path.module}/values.yaml")]
`;

  let varsFile = makeVar('namespace', 'string', dc.namespace);

  // Cluster vars
  k8sMonFile += makeSet('cluster.name');
  varsFile += makeVar('cluster.name', 'string', dc.cluster);

  // Metrics service vars
  if (dc.features.metrics) {
    k8sMonFile +=
      makeSet('externalServices.prometheus.host') +
      makeSetSensitive('externalServices.prometheus.basicAuth.username') +
      makeSetSensitive('externalServices.prometheus.basicAuth.password');
    varsFile +=
      makeVar('externalServices.prometheus.host', 'string', hostedDetails?.hmInstancePromUrl || 'METRICS_HOST') +
      makeVar(
        'externalServices.prometheus.basicAuth.username',
        'number',
        hostedDetails?.hmInstancePromId || 'METRICS_USERNAME'
      ) +
      makeVar('externalServices.prometheus.basicAuth.password', 'string', accessPolicyToken);
  }

  // Logs service vars
  if (dc.features.podLogs || dc.features.clusterEvents) {
    k8sMonFile +=
      makeSet('externalServices.loki.host') +
      makeSetSensitive('externalServices.loki.basicAuth.username') +
      makeSetSensitive('externalServices.loki.basicAuth.password');
    varsFile +=
      makeVar('externalServices.loki.host', 'string', hostedDetails?.hlInstanceUrl || 'LOGS_HOST') +
      makeVar('externalServices.loki.basicAuth.username', 'number', hostedDetails?.hlInstanceId || 'LOGS_USERNAME') +
      makeVar('externalServices.loki.basicAuth.password', 'string', accessPolicyToken);
  }

  // Traces service vars
  if (dc.features.otelReceivers || dc.features.zipkinReceiver) {
    k8sMonFile +=
      makeSet('externalServices.tempo.host') +
      makeSetSensitive('externalServices.tempo.basicAuth.username') +
      makeSetSensitive('externalServices.tempo.basicAuth.password');
    varsFile +=
      makeVar(
        'externalServices.tempo.host',
        'string',
        hostedDetails?.htInstanceUrl ? `${hostedDetails?.htInstanceUrl}:443` : 'TEMPO_HOST'
      ) +
      makeVar('externalServices.tempo.basicAuth.username', 'number', hostedDetails?.htInstanceId || 'TEMPO_USERNAME') +
      makeVar('externalServices.tempo.basicAuth.password', 'string', accessPolicyToken);
  }

  // OpenCost deployment vars
  if (dc.features.costMetrics) {
    k8sMonFile += makeSet('opencost.opencost.exporter.defaultClusterId', `var.${pathToVarName('cluster.name')}`);
    k8sMonFile += makeSet(
      'opencost.opencost.prometheus.external.url',
      `format("%s/api/prom", var.${pathToVarName('externalServices.prometheus.host')})`
    );

    // Openshift platform
    if (platform === Platforms.Openshift) {
      values.cluster = {
        platform: Platforms.Openshift,
      };
    }
  }

  k8sMonFile += '}';

  return {
    providerFile,
    k8sMonFile,
    varsFile,
    valuesFile: stringify(values),
  };
};

const pathToVarName = (path: string): string =>
  path
    .replaceAll('.', '_')
    .replaceAll('-', '_')
    .replaceAll('/', '_')
    .replaceAll('[', '_')
    .replaceAll(']', '_')
    .toLowerCase();

const makeSet = (path: string, value?: string, type?: string): string => {
  if (!value) {
    value = `var.${pathToVarName(path)}`;
  } else if (type === 'string') {
    value = `"${value}"`;
  }
  return `
  set {
    name  = "${path}"
    value = ${value}
  }
`;
};

const makeSetSensitive = (path: string, value?: string, type?: string): string => {
  if (!value) {
    value = `var.${pathToVarName(path)}`;
  } else if (type === 'string') {
    value = `"${value}"`;
  }
  return `
  set_sensitive {
    name  = "${path}"
    value = ${value}
  }
`;
};

// const makeSetList = (path: string, values: any[]): string => {
//   const preppedValues = values.map(
//     (value) =>
//       '"' + JSON.stringify(value).replaceAll('{', '\\\\{').replaceAll('}', '\\\\}').replaceAll('"', '\\"') + '"'
//   );
//   return `
//   set_list {
//     name  = "${path}"
//     value = [${preppedValues}]
//   }
// `;
// };

const makeVar = (path: string, type: string, value: any): string => {
  if (type === 'string') {
    value = `"${value}"`;
  }
  return `
variable "${pathToVarName(path)}" {
  type    = ${type}
  default = ${value}
}
`;
};
