import { ThresholdsMode } from '@grafana/data';
import { PanelBuilders } from '@grafana/scenes';
import {
  AxisColorMode,
  AxisPlacement,
  BigValueColorMode,
  BigValueGraphMode,
  BigValueJustifyMode,
  BigValueTextMode,
  GraphGradientMode,
  GraphThresholdsStyleMode,
  LegendDisplayMode,
  ScaleDistribution,
  SortOrder,
  StackingMode,
  TableCellDisplayMode,
  TableCellHeight,
  TooltipDisplayMode,
  VisibilityMode,
  VizOrientation,
} from '@grafana/schema';
import { PanelConfig } from 'components/scenes/CostsOverviewScene/panelConfig';
import jsonToPanelBuilders from 'helpers/jsonToPanelBuilders';
import { BarChartPanelType, StatPanelType, TablePanelType } from 'types';

export const metricsPerCluster = (panel: PanelConfig) => {
  const panelBuilder = PanelBuilders.barchart();

  return jsonToPanelBuilders<BarChartPanelType>(panelBuilder, {
    title: panel.title,
    description: panel.description,
    $data: panel.runner,
    fieldConfig: {
      defaults: {
        custom: {
          lineWidth: 1,
          fillOpacity: 80,
          gradientMode: GraphGradientMode.None,
          axisPlacement: AxisPlacement.Auto,
          axisLabel: '',
          axisColorMode: AxisColorMode.Text,
          axisBorderShow: false,
          scaleDistribution: {
            type: ScaleDistribution.Linear,
          },
          axisCenteredZero: false,
          hideFrom: {
            tooltip: false,
            viz: false,
            legend: false,
          },
          thresholdsStyle: {
            mode: GraphThresholdsStyleMode.Off,
          },
        },
        color: {
          mode: 'continuous-BlPu',
        },
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
          ],
        },
        unit: 'short',
      },
      overrides: [],
    },
    options: {
      orientation: VizOrientation.Auto,
      xTickLabelRotation: 30,
      xTickLabelSpacing: 0,
      showValue: VisibilityMode.Never,
      stacking: StackingMode.None,
      groupWidth: 0.7,
      barWidth: 0.75,
      barRadius: 0,
      fullHighlight: false,
      tooltip: {
        mode: TooltipDisplayMode.Single,
        sort: SortOrder.None,
        maxHeight: 600,
      },
      legend: {
        showLegend: false,
        displayMode: LegendDisplayMode.List,
        placement: 'right',
        calcs: [],
      },
    },
  }).setData(panel.runner);
};

export const totalSeriesForClusters = (panel: PanelConfig) => {
  const panelBuilder = PanelBuilders.stat();
  return jsonToPanelBuilders<StatPanelType>(panelBuilder, {
    title: panel.title,
    description: panel.description,
    fieldConfig: {
      defaults: {
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
          ],
        },
        color: {
          mode: 'continuous-BlPu',
        },
        unit: 'short',
      },
      overrides: [],
    },
    options: {
      reduceOptions: {
        values: false,
        calcs: ['lastNotNull'],
        fields: '',
      },
      orientation: VizOrientation.Auto,
      textMode: BigValueTextMode.Auto,
      wideLayout: true,
      colorMode: BigValueColorMode.Background,
      graphMode: BigValueGraphMode.Area,
      justifyMode: BigValueJustifyMode.Auto,
      showPercentChange: false,
    },
  }).setData(panel.runner);
};

export const topMetricsPerCluster = (panel: PanelConfig, cluster: string) => {
  const panelBuilder = PanelBuilders.table();
  return jsonToPanelBuilders<TablePanelType>(panelBuilder, {
    title: panel.title,
    description: panel.description,
    fieldConfig: {
      defaults: {
        custom: {
          align: 'auto',
          cellOptions: {
            type: TableCellDisplayMode.Auto,
          },
          inspect: false,
        },
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
            {
              color: 'red',
              value: 80,
            },
          ],
        },
        color: {
          mode: 'fixed',
        },
      },
      overrides: [
        {
          matcher: {
            id: 'byName',
            options: 'Metric',
          },
          properties: [
            {
              id: 'links',
              value: [
                {
                  title: 'View detailed cardinality dashboard for metric: ${__data.fields.Metric}',
                  url:
                    'd/cardinality-management-metrics-detail/?var-metric=${__data.fields.Metric}&${datasource:queryparam}&var-filter=cluster%7C%3D%7C' +
                    cluster,
                },
              ],
            },
          ],
        },
      ],
    },

    options: {
      showHeader: true,
      cellHeight: TableCellHeight.Sm,
      footer: {
        show: false,
        reducer: ['sum'],
        countRows: false,
        fields: [''],
      },
      sortBy: [
        {
          desc: true,
          displayName: 'Count of series',
        },
      ],
    },
  }).setData(panel.runner);
};

export const seriesPerNamespace = (panel: PanelConfig, cluster: string) => {
  const panelBuilder = PanelBuilders.table();
  return jsonToPanelBuilders<TablePanelType>(panelBuilder, {
    title: panel.title,
    description: panel.description,
    fieldConfig: {
      defaults: {
        color: {
          mode: 'continuous-BlPu',
        },
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
          ],
        },
        links: [
          {
            title: 'View overview cardinality dashboard scoped to namespace: ${__data.fields[0]}',
            url:
              'd/cardinality-management/?${datasource:queryparam}&var-filter=namespace%7C%3D%7C${__data.fields[0]}&var-filter=cluster%7C%3D%7C' +
              cluster,
          },
        ],
        unit: 'short',
      },
      overrides: [],
    },
  }).setData(panel.runner);
};

export const seriesPerNode = (panel: PanelConfig, cluster: string) => {
  const panelBuilder = PanelBuilders.barchart();

  return jsonToPanelBuilders<BarChartPanelType>(panelBuilder, {
    title: panel.title,
    description: panel.description,
    fieldConfig: {
      defaults: {
        custom: {
          lineWidth: 1,
          fillOpacity: 80,
          gradientMode: GraphGradientMode.None,
          axisPlacement: AxisPlacement.Auto,
          axisLabel: '',
          axisColorMode: AxisColorMode.Text,
          axisBorderShow: false,
          scaleDistribution: {
            type: ScaleDistribution.Linear,
          },
          axisCenteredZero: false,
          hideFrom: {
            tooltip: false,
            viz: false,
            legend: false,
          },
          thresholdsStyle: {
            mode: GraphThresholdsStyleMode.Off,
          },
          axisGridShow: true,
        },
        color: {
          mode: 'continuous-BlPu',
        },
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
          ],
        },
        links: [
          {
            title: 'View overview cardinality dashboard scoped to node: ${__data.fields[0]}',
            url:
              'd/cardinality-management/?${datasource:queryparam}&var-filter=node%7C%3D%7C${__data.fields[0]}&var-filter=cluster%7C%3D%7C' +
              cluster,
          },
        ],
        unit: 'short',
      },
      overrides: [],
    },

    options: {
      orientation: VizOrientation.Horizontal,
      xTickLabelRotation: 0,
      xTickLabelSpacing: 0,
      showValue: VisibilityMode.Never,
      stacking: StackingMode.None,
      groupWidth: 0.7,
      barWidth: 0.75,
      barRadius: 0,
      fullHighlight: false,
      tooltip: {
        mode: TooltipDisplayMode.Single,
        sort: SortOrder.None,
        maxHeight: 600,
      },
      legend: {
        showLegend: false,
        displayMode: LegendDisplayMode.List,
        placement: 'right',
        calcs: [],
      },
      colorByField: 'Count of series',
    },
  }).setData(panel.runner);
};

export const topSeriesPerPod = (panel: PanelConfig, cluster: string) => {
  const panelBuilder = PanelBuilders.barchart();

  return jsonToPanelBuilders<BarChartPanelType>(panelBuilder, {
    title: panel.title,
    description: panel.description,
    fieldConfig: {
      defaults: {
        custom: {
          lineWidth: 1,
          fillOpacity: 80,
          gradientMode: GraphGradientMode.None,
          axisPlacement: AxisPlacement.Auto,
          axisLabel: '',
          axisColorMode: AxisColorMode.Text,
          axisBorderShow: false,
          scaleDistribution: {
            type: ScaleDistribution.Linear,
          },
          axisCenteredZero: false,
          hideFrom: {
            tooltip: false,
            viz: false,
            legend: false,
          },
          thresholdsStyle: {
            mode: GraphThresholdsStyleMode.Off,
          },
          axisGridShow: true,
        },
        color: {
          mode: 'continuous-BlPu',
        },
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Percentage,
          steps: [
            {
              color: 'green',
              value: 0,
            },
          ],
        },
        links: [
          {
            title: 'View overview cardinality dashboard scoped to pod: ${__data.fields[0]}',
            url:
              'd/cardinality-management/?${datasource:queryparam}&var-filter=pod%7C%3D%7C${__data.fields[0]}&var-filter=cluster%7C%3D%7C' +
              cluster,
          },
        ],
        unit: 'short',
      },
      overrides: [],
    },

    options: {
      orientation: VizOrientation.Horizontal,
      xTickLabelRotation: 0,
      xTickLabelSpacing: 0,
      showValue: VisibilityMode.Never,
      stacking: StackingMode.None,
      groupWidth: 0.7,
      barWidth: 0.82,
      barRadius: 0,
      fullHighlight: false,
      tooltip: {
        mode: TooltipDisplayMode.Single,
        sort: SortOrder.None,
        maxHeight: 600,
      },
      legend: {
        showLegend: false,
        displayMode: LegendDisplayMode.List,
        placement: 'right',
        calcs: [],
      },
      colorByField: 'Count of series',
    },
  }).setData(panel.runner);
};
