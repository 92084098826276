import React, { useState, useCallback, useMemo, useContext, useEffect } from 'react';
import { sortBy } from 'lodash';
import { ClusterNodeConditionResult, ClusterNodeResult, GeneralQueryResult, Node, NodePods, StoredParams } from 'types';
import { ExpanderComponentProps, TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import { LoadingPlaceholder, MultiSelect, Select, Tag, Tooltip, useStyles2 } from '@grafana/ui';
import { SelectableValue } from '@grafana/data';
import Table from 'components/Table/Table';
import { NodeQueries, ResourceQueries } from 'queries';
import { RudderstackEvents, TableType } from 'enums';
import useRudderstack from 'hooks/useRudderstack';
import ExploreButton from 'components/ExploreButton/ExploreButton';
import { QueryCollection, countFiltersApplied, encodeUrlString } from 'helpers/helpers';
import { getProvider } from 'helpers/nodes';
import useDatasourceStore from 'store/datasource';
import { prometheusSelector } from 'store/selectors/datasource';

import Styles from './NodesList.styles';
import { parseNodePods } from './helpers';
import useLocalStorage from 'hooks/useLocalStorage';
import { K8S_STORAGE_KEY } from '../../constants';
import useTimeRangeStore from 'store/timeRange';
import PredictionModal from '../PredictionModal/PredictionModal';
import ExpandRow from 'components/Table/TableExpandRow';
import { TableView } from 'store/filters';
import { PluginMetaContext } from 'context/PluginMetaContext';
import { pushFaroCount } from 'helpers/faro';
import CopyObjectName from 'components/Table/CopyObjectName';
import QueryParamsLink from 'components/QueryParamsLink/QueryParamsLink';
import useCostUsage from 'hooks/useCostUsage';
import FiltersRow from 'components/FiltersRow/FiltersRow';
import { getAllColumns } from 'helpers/usageCostHelpers';
import useAssertsStore from 'store/asserts';
import { assertsColumns, selectableRowDisabled, selectableRowSelected } from 'components/Asserts/helpers';
import AssertsWorkbenchButton from 'components/Asserts/AssertsWorkbenchButton';
import alertColumn from 'components/Table/alertColumn';
import useDataFrame from 'hooks/useDataFrame';

const TableActions: React.FC<ExpanderComponentProps<Node>> = ({ data }) => {
  return (
    <ExpandRow
      actions={
        <>
          <PredictionModal
            buttonText="Predict Mem Usage"
            title="Memory Usage Prediction Model"
            query={NodeQueries.NodeMemory(data.node)}
            name="Node Memory usage"
            metric="node_namespace_pod_container:container_memory_working_set_bytes"
            axisLabel="Bytes"
          />
          <PredictionModal
            buttonText="Predict CPU Usage"
            title="CPU Usage Prediction Model"
            query={NodeQueries.CpuUsage(data.node)}
            name="Node CPU usage"
            metric="node_cpu_seconds_total"
            hyperParamsUpperLimit={100}
          />
        </>
      }
      data={[
        { title: 'pods', value: String(data.podCount) },
        { title: 'condition', value: data.condition },
      ]}
    />
  );
};

const NodesList = ({
  withFilters = true,
  queries,
  onData,
  hideClusterColumn,
  hideProviderColumn,
  defaultClusters,
}: {
  withFilters: boolean;
  queries: string[] | ((cluster: string[]) => string[]);
  onData?: (data: unknown) => void;
  hideClusterColumn?: boolean;
  hideProviderColumn?: boolean;
  defaultClusters?: string[];
}) => {
  const meta = useContext(PluginMetaContext);
  const [storedParams, setStoredParams] = useLocalStorage<StoredParams>(K8S_STORAGE_KEY);
  const styles = useStyles2(Styles);
  const prometheusName = useDatasourceStore(prometheusSelector);
  const [assertsEnabled, setSelectedAssertsRows, selectedAssertsRows, toggledClearRows] = useAssertsStore((state) => [
    state.assertsEnabled,
    state.setSelectedAssertsRows,
    state.selectedAssertsRows,
    state.toggledClearRows,
  ]);
  const [clusterFilter, setClusterFilter] = useState(storedParams?.cluster || []);
  const [conditionFilter, setConditionFilter] = useState(storedParams?.condition ?? '');
  const [providerFilter, setProviderFilter] = useState(storedParams?.nodeProvider || '');
  const [nodesFilter, setNodesFilter] = useState<string[]>(storedParams?.node || []);
  const trackRudderStackEvent = useRudderstack();
  const [range, hasDateChanged, relativeRange] = useTimeRangeStore((state) => [
    state.range,
    state.hasDateChanged,
    state.relativeRange,
  ]);

  const extractedQueries = typeof queries === 'function' ? queries(clusterFilter) : queries;

  const { loading, data, error, firstLoad } = useDataFrame(
    extractedQueries,
    { from: range.from.valueOf(), to: range.to.valueOf() },
    '', // auto step
    !hasDateChanged,
    1,
    true,
    true,
    true,
    clusterFilter
  );

  const [nodesError] = error;
  const [nodesConditionData, podsPerNodeData, allNodeData, alertsData] = data as [
    Array<GeneralQueryResult<ClusterNodeConditionResult>>,
    Array<GeneralQueryResult<NodePods>>,
    Array<GeneralQueryResult<Node>>,
    Array<GeneralQueryResult<ClusterNodeResult>>
  ];

  const nodesData = useMemo<Node[]>(() => {
    const alertMap = new Map<string, number>();
    alertsData?.forEach?.((metric) => {
      const key = `${metric.metric.cluster}-${metric.metric.node}`;
      alertMap.set(key, parseInt(metric.value[1], 10));
    });

    const pods = parseNodePods(podsPerNodeData);

    const nodeMap = new Map<string, Node>();
    allNodeData?.forEach?.((metric) => {
      const key = `${metric.metric.cluster}-${metric.metric.node}`;
      nodeMap.set(key, {
        cluster: metric.metric.cluster || 'No data',
        node: metric.metric.node?.split(':')[0],
        condition: 'No data', // overwritten by nodesConditionData if present
        provider: getProvider(metric),
        asserts_env: metric.metric.asserts_env,
        asserts_site: metric.metric.asserts_site,
        alertCount: alertMap.get(key) || 0,
        podCount: pods[metric.metric.node] || 'No data',
      } as Node);
    });

    nodesConditionData?.forEach?.((metric) => {
      const key = `${metric.metric.cluster}-${metric.metric.node}`;
      const current = nodeMap.get(key);
      if (current) {
        nodeMap.set(key, {
          ...current,
          condition: metric.metric.condition,
        });
      } else {
        nodeMap.set(key, {
          cluster: metric.metric.cluster || 'No data',
          node: metric.metric.node?.split(':')[0],
          condition: metric.metric.condition,
          alertCount: alertMap.get(key) || 0,
        } as Node);
      }
    });

    pushFaroCount('node-count', nodeMap.size);

    const { __name__, instance, node, provider_id, cluster, ...rest } = allNodeData?.[0]?.metric || {};
    onData?.({
      'cluster name': cluster,
      'nodes count': allNodeData?.length,
      provider_id: getProvider(allNodeData?.[0]),
      ...rest,
    });

    return [...nodeMap.values()];
  }, [podsPerNodeData, nodesConditionData, allNodeData, onData, alertsData]);

  const { push } = useHistory();

  const handleConditionFilterUpdate = (filter?: SelectableValue) => {
    setConditionFilter(filter?.value);
    setStoredParams({ condition: filter?.value });
  };

  const handleProviderFilterUpdate = (filter?: SelectableValue) => {
    setProviderFilter(filter?.value);
    setStoredParams({ nodeProvider: filter?.value });
  };

  const handleClusterFilterUpdate = useCallback(
    (filters: SelectableValue[] = []) => {
      const filterArray = filters?.map?.((filter) => filter.value);
      setClusterFilter(filterArray);
      setStoredParams({ cluster: filterArray });
    },
    [setStoredParams]
  );

  const handleNodesFilterUpdate = useCallback(
    (filters: SelectableValue[] = []) => {
      const filterArray = filters?.map?.((filter) => filter.value);
      setNodesFilter(filterArray);
      setStoredParams({ node: filterArray });
    },
    [setStoredParams]
  );

  const filteredNodes = useMemo(() => {
    if (!withFilters) {
      return nodesData?.filter?.((node) => {
        const matchesNode = nodesFilter?.some((item) => node.node.includes(item));
        return !nodesFilter?.length || matchesNode;
      });
    }

    return nodesData?.filter?.((node) => {
      const matchesCluster = clusterFilter?.some((cluster) => node?.cluster?.includes(cluster));
      const matchesCondition = node?.condition?.includes(conditionFilter);
      const matchesProvider = node?.provider?.includes(providerFilter);
      const matchesNode = nodesFilter?.some((item) => node?.node?.includes(item));

      return (
        (!conditionFilter || matchesCondition) &&
        (!clusterFilter?.length || matchesCluster) &&
        (!providerFilter || matchesProvider) &&
        (!nodesFilter?.length || matchesNode)
      );
    });
  }, [nodesData, clusterFilter, conditionFilter, withFilters, providerFilter, nodesFilter]);

  const [clusterOptions, conditionOptions, providerOptions, nodeOptions] = useMemo(() => {
    const clusterOpts = new Map<string, SelectableValue>();
    const conditionOpts = new Map<string, SelectableValue>();
    const providerOpts = new Map<string, SelectableValue>();
    const nodeOpts = new Map<string, SelectableValue>();

    nodesData?.forEach?.(({ node, cluster, condition, provider }) => {
      conditionOpts.set(condition, {
        label: condition,
        value: condition,
      });

      providerOpts.set(provider, {
        label: provider,
        value: provider,
      });

      nodeOpts.set(node, {
        label: node,
        value: node,
      });

      if (!defaultClusters?.length && (!conditionFilter || condition.includes(conditionFilter))) {
        clusterOpts.set(cluster, {
          label: cluster,
          value: cluster,
        });
      }
    });

    if (defaultClusters?.length) {
      defaultClusters?.forEach?.((cluster) => {
        clusterOpts.set(cluster, {
          label: cluster,
          value: cluster,
        });
      });
    }

    return [
      sortBy([...clusterOpts.values()], 'value'),
      sortBy([...conditionOpts.values()], 'value'),
      sortBy([...providerOpts.values()], 'value'),
      sortBy([...nodeOpts.values()], 'value'),
    ];
  }, [nodesData, conditionFilter, defaultClusters]);

  const exploreRange = {
    from: relativeRange?.from || range.from.valueOf().toString(),
    to: relativeRange?.to || range.to.valueOf().toString(),
  };

  useEffect(() => {
    handleConditionFilterUpdate(undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [exploreRange.from, exploreRange.to]);

  const pipeClusters = clusterFilter?.join('|');

  const {
    usageLoading,
    usageCostTableData,
    costLoading,
    firstCostLoad,
    firstUsageLoad,
    tableView,
    onTableSort,
    sortColumn,
    exploreUrl,
  } = useCostUsage<Node>({
    type: 'node',
    initialData: filteredNodes,
    usageQueries: new QueryCollection(prometheusName, exploreRange, true, ResourceQueries.NodeUsage, [pipeClusters]),
    costQueries: new QueryCollection(prometheusName, exploreRange, true, ResourceQueries.NodeCost, [pipeClusters]),
  });

  const columns: Array<TableColumn<Node>> = [
    ...assertsColumns<Node>(),
    {
      name: 'Node',
      selector: (row: Node) => row.node,
      sortable: true,
      sortFunction: (a: Node, b: Node) => a.node.localeCompare(b.node, undefined, { numeric: true }),
      // eslint-disable-next-line react/display-name
      cell: (row: Node) => (
        <CopyObjectName
          link={`/a/${meta.id}/navigation/nodes/${encodeUrlString(row.cluster)}/${row.node}`}
          value={row.node}
        />
      ),
      maxWidth: '400px',
      minWidth: '200px',
    },
    {
      name: 'Cluster',
      selector: (row: Node) => row.cluster,
      sortable: true,
      sortFunction: (a: Node, b: Node) => a.cluster.localeCompare(b.cluster, undefined, { numeric: true }),
      // eslint-disable-next-line react/display-name
      cell: (row: Node) => (
        <Tooltip content={row.cluster}>
          <QueryParamsLink
            to={`/a/${meta.id}/navigation/cluster/${encodeUrlString(row.cluster)}`}
            className={styles.link}
            label={<Tag name={row.cluster} className={styles.clusterTag} />}
          />
        </Tooltip>
      ),
      omit: !!hideClusterColumn,
    },
    ...(tableView === TableView.Usage
      ? [
          {
            name: 'Provider',
            selector: (row: Node) => row?.provider,
            sortable: true,
            omit: !!hideProviderColumn,
          },

          {
            name: 'Condition',
            selector: (row: Node) => row.condition,
            sortable: true,
            omit: true,
          },
        ]
      : []),
  ];

  const { currentColumns, extraColumns } = getAllColumns<Node>(
    tableView === TableView.Cost,
    columns,
    [],
    [alertColumn<Node>({ hasDateChanged, prometheusName })],
    usageCostTableData.errors,
    usageLoading && firstUsageLoad,
    costLoading && firstCostLoad
  );

  const handleSelectedRowsChange = useCallback(
    ({ selectedRows }: { selectedRows: Node[] }) => {
      setSelectedAssertsRows(selectedRows);
    },
    [setSelectedAssertsRows]
  );

  const selectableRowsCallback = useMemo(() => selectableRowSelected<Node>('node'), []);

  return (
    <div className={styles.wrapper}>
      <FiltersRow
        withFilters={withFilters}
        visibleFilterCount={countFiltersApplied([clusterFilter, nodesFilter])}
        hiddenFilterCount={countFiltersApplied([conditionFilter, providerFilter])}
        extraButtons={<ExploreButton href={exploreUrl} label="Explore nodes" size="md" style={{ marginRight: 0 }} />}
        visibleFilters={
          withFilters ? (
            <>
              <MultiSelect
                width={27}
                isClearable
                placeholder="Cluster"
                options={clusterOptions}
                value={clusterFilter}
                onChange={handleClusterFilterUpdate}
                maxVisibleValues={1}
              />
              <MultiSelect
                width={25}
                isClearable
                placeholder="Filter by node"
                options={nodeOptions}
                value={nodesFilter}
                onChange={handleNodesFilterUpdate}
                maxVisibleValues={1}
              />
              <AssertsWorkbenchButton label="Nodes" type="Node" nameKey="node" relativeRange={relativeRange} />
            </>
          ) : (
            <>
              <MultiSelect
                width={25}
                isClearable
                placeholder="Filter by node"
                options={nodeOptions}
                value={nodesFilter}
                onChange={handleNodesFilterUpdate}
                maxVisibleValues={1}
              />
              <AssertsWorkbenchButton label="Nodes" type="Node" nameKey="node" relativeRange={relativeRange} />
            </>
          )
        }
        hiddenFilters={
          withFilters ? (
            <>
              <Select
                width={25}
                isClearable
                placeholder="Condition"
                options={conditionOptions}
                value={conditionFilter}
                onChange={handleConditionFilterUpdate}
              />
              <Select
                isClearable
                width={25}
                placeholder="Provider"
                options={providerOptions}
                value={providerFilter}
                onChange={handleProviderFilterUpdate}
              />
            </>
          ) : undefined
        }
      />

      {!nodesData?.length ||
      ((loading || usageLoading || costLoading) && !Object.keys(nodesError || {}).length && firstLoad) ? (
        <LoadingPlaceholder text="Loading nodes" />
      ) : (
        <>
          <Table<Node>
            key={sortColumn}
            withNestedHeader
            onSort={onTableSort}
            id={TableType.Node}
            name={TableType.Node}
            data={usageCostTableData.data}
            columns={[...currentColumns, ...extraColumns]}
            defaultSortField="cpu"
            noDataText="No nodes found"
            expandableRows
            expandableComponent={TableActions}
            selectable={assertsEnabled}
            onSelectedRowsChange={handleSelectedRowsChange}
            selectableRowDisabled={(row) => selectableRowDisabled<Node>(row, selectedAssertsRows)}
            selectableRowSelected={selectableRowsCallback}
            clearSelectedRows={toggledClearRows}
            onRowClicked={(row) => {
              trackRudderStackEvent(RudderstackEvents.NamespaceListClick, {});
              push(`/a/${meta.id}/navigation/nodes/${encodeUrlString(row.cluster)}/${row.node}`);
            }}
          />
        </>
      )}
    </div>
  );
};

export default NodesList;
