import { css } from '@emotion/css';
import { GrafanaTheme2 } from '@grafana/data';

const podDetailStyles = (theme: GrafanaTheme2) => ({
  wrapper: css`
    width: 100%;
  `,
  grid: css`
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
  `,
  gridEqual: css`
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 10px;
    margin-bottom: 0;
    margin-top: ${theme.spacing(1)};
  `,
  header: css`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
  `,
  container: css`
    /* max-height: 80vh; */
    min-height: 80vh;
  `,
  marginBottom: css`
    margin-bottom: 20px;
  `,
  loadingIndicator: css`
    margin-right: ${theme.spacing(1)};
  `,
  logs: css`
    max-height: 400px;
    overflow-y: scroll;
    overflow-x: hidden;
  `,
  cardsWrapper: css`
    margin: 30px 0;
  `,
  cardsTitle: css`
    display: flex;
  `,
  card: css`
    align-items: start;
  `,
  cpuCard: css`
    padding: ${theme.spacing(2)};
    background: ${theme.colors.background.secondary};
    margin-bottom: ${theme.spacing(1)};
    height: 120px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  `,
  cardHeader: css`
    font-size: 14px;
    letter-spacing: inherit;
    line-height: 1.5;
    color: ${theme.colors.text.primary};
    font-weight: 500;
  `,
  cardBodyText: css`
    color: ${theme.colors.text.secondary};
    margin-bottom: ${theme.spacing(1)};
  `,
  exploreButtonsWrapper: css`
    display: flex;
    margin-top: -${theme.spacing(3)};
    margin-bottom: ${theme.spacing(2)};
    margin-left: -${theme.spacing(1)};
  `,
  explore: css`
    position: absolute;
    top: 2px;
    right: 8px;
  `,
  titleWrapper: css`
    display: flex;
  `,
  exploreContainerWrapper: css`
    display: flex;
  `,
  infoWrapper: css`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: ${theme.spacing(2)} 0;
    gap: ${theme.spacing(4)};
    flex-wrap: wrap;
  `,
  list: css`
    width: 49%;
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  `,
  largeList: css`
    li > span:first-child {
      min-width: 350px;
    }
  `,
  listItem: css`
    padding: 0 ${theme.spacing(3)};
    display: flex;
    border-bottom: 1px solid ${theme.colors.border.weak};
    background-color: ${theme.colors.background.secondary};

    &:first-child {
      padding-top: 4px;
    }

    &:last-child {
      padding-bottom: 4px;
    }

    > span {
      display: inline-block;
      padding: 8px;

      &:first-child {
        width: 280px;
        font-weight: ${theme.typography.fontWeightBold};
        font-size: 15px;
      }

      &:last-child {
        width: 100%;
      }
    }
  `,
  link: css`
    text-decoration: underline;
    margin-right: 5px;
    transition: color 0.2s ease;
    &:hover {
      color: rgba(204, 204, 220, 0.65);
    }
    height: auto;
    display: block;
  `,
  longCell: css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  info: css`
    position: absolute;
    right: 3px;
    top: 1px;
    cursor: pointer;
  `,
  sectionTitle: css`
    font-size: 17px;
    margin-bottom: ${theme.spacing(2)};
    margin-right: ${theme.spacing(2)};
  `,
  sectionSpacing: css`
    margin-bottom: ${theme.spacing(4)};
  `,
  sceneWrapper: css`
    min-height: 200px;

    > div > div > div > div {
      @media (max-width: 1200px) {
        flex-wrap: wrap;
        width: 100%;

        > div {
          width: 49%;
        }
      }

      @media (max-width: 768px) {
        width: 100%;

        > div {
          width: 100%;
        }
      }
    }
  `,
});

export default podDetailStyles;
