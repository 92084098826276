import { ThresholdsMode } from '@grafana/data';
import { PanelConfig } from '../CostsOverviewScene/panelConfig';
import { PanelBuilders } from '@grafana/scenes';
import { TablePanelType, TimeSeriesPanelType } from 'types';
import jsonToPanelBuilders from 'helpers/jsonToPanelBuilders';
import {
  AxisColorMode,
  AxisPlacement,
  GraphDrawStyle,
  GraphGradientMode,
  GraphThresholdsStyleMode,
  LegendDisplayMode,
  LineInterpolation,
  ScaleDistribution,
  SortOrder,
  StackingMode,
  TableCellHeight,
  TooltipDisplayMode,
  VisibilityMode,
} from '@grafana/schema';

export const timeseriesConfig = (panel: PanelConfig) => {
  const panelBuilder = PanelBuilders.timeseries();

  return jsonToPanelBuilders<TimeSeriesPanelType>(panelBuilder, {
    $data: panel.runner,
    title: panel.title,
    fieldConfig: {
      defaults: {
        color: {
          mode: 'palette-classic',
        },
        custom: {
          axisBorderShow: false,
          axisCenteredZero: false,
          axisColorMode: AxisColorMode.Text,
          axisGridShow: false,
          axisLabel: '',
          axisPlacement: AxisPlacement.Left,
          barAlignment: 0,
          drawStyle: GraphDrawStyle.Line,
          fillOpacity: 20,
          gradientMode: GraphGradientMode.Hue,
          hideFrom: {
            legend: false,
            tooltip: false,
            viz: false,
          },
          insertNulls: false,
          lineInterpolation: LineInterpolation.Smooth,
          lineStyle: {
            fill: 'solid',
          },
          lineWidth: 2,
          pointSize: 5,
          scaleDistribution: {
            type: ScaleDistribution.Linear,
          },
          showPoints: VisibilityMode.Never,
          spanNulls: false,
          stacking: {
            group: 'A',
            mode: StackingMode.None,
          },
          thresholdsStyle: {
            mode: GraphThresholdsStyleMode.Off,
          },
        },
        fieldMinMax: false,
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
            {
              color: 'red',
              value: 80,
            },
          ],
        },
      },
      overrides: [
        {
          matcher: {
            id: 'byRegexp',
            options: 'info|notify|proactive|low',
          },
          properties: [
            {
              id: 'color',
              value: {
                fixedColor: 'text',
                mode: 'fixed',
              },
            },
          ],
        },
        {
          matcher: {
            id: 'byRegexp',
            options: 'warn|warning',
          },
          properties: [
            {
              id: 'color',
              value: {
                fixedColor: 'orange',
                mode: 'fixed',
              },
            },
          ],
        },
        {
          matcher: {
            id: 'byRegexp',
            options: 'critical',
          },
          properties: [
            {
              id: 'color',
              value: {
                fixedColor: 'red',
                mode: 'fixed',
              },
            },
          ],
        },
      ],
    },
    options: {
      legend: {
        calcs: ['last'],
        displayMode: LegendDisplayMode.Table,
        showLegend: true,
        sortBy: 'Last',
        sortDesc: true,
      },
      tooltip: {
        mode: TooltipDisplayMode.Single,
        sort: SortOrder.None,
      },
    },
  }).setData(panel.runner);
};

export const tableConfig = (panel: PanelConfig) => {
  const panelBuilder = PanelBuilders.table();

  return jsonToPanelBuilders<TablePanelType>(panelBuilder, {
    title: panel.title,
    pluginId: 'table',
    options: {
      cellHeight: TableCellHeight.Md,
      footer: {
        countRows: false,
        enablePagination: true,
        fields: [''],
        reducer: ['count'],
        show: false,
      },
      showHeader: true,
    },
    fieldConfig: {
      defaults: {
        custom: {
          filterable: true,
        },
      },
      overrides: [
        {
          matcher: {
            id: 'byRegexp',
            options: '/workload_type|job|encoded_cluster/',
          },
          properties: [
            {
              id: 'custom.hidden',
              value: true,
            },
          ],
        },
        {
          matcher: {
            id: 'byName',
            options: 'alertname',
          },
          properties: [
            {
              id: 'links',
              value: [
                {
                  title: 'Go to alert',
                  url:
                    '/alerting/list?view=state&search=' +
                    [
                      'type:alerting',
                      'datasource:"${datasource:percentencode}"',
                      'rule:${__data.fields.alertname:percentencode}',
                      'label:cluster=${__data.fields.cluster:percentencode}',
                    ].join('%20'),
                  targetBlank: true,
                },
              ],
            },
          ],
        },
        {
          matcher: {
            id: 'byName',
            options: 'cluster',
          },
          properties: [
            {
              id: 'links',
              value: [
                {
                  title: 'Go to cluster',
                  url: '/a/grafana-k8s-app/navigation/cluster/${__data.fields.encoded_cluster}${__url.params:exclude:var-loki,var-severity,var-cluster,var-datasource,var-namespace}',
                },
              ],
            },
          ],
        },
        {
          matcher: {
            id: 'byName',
            options: 'node',
          },
          properties: [
            {
              id: 'links',
              value: [
                {
                  title: 'Go to node',
                  url: '/a/grafana-k8s-app/navigation/nodes/${__data.fields.encoded_cluster}/${__data.fields.node:percentencode}${__url.params:exclude:var-loki,var-severity,var-cluster,var-datasource,var-namespace}',
                },
              ],
            },
          ],
        },
        {
          matcher: {
            id: 'byName',
            options: 'namespace',
          },
          properties: [
            {
              id: 'links',
              value: [
                {
                  title: 'Go to namespace',
                  url: '/a/grafana-k8s-app/navigation/namespace/${__data.fields.encoded_cluster}/${__data.fields.namespace}${__url.params:exclude:var-loki,var-severity,var-cluster,var-datasource,var-namespace}',
                },
              ],
            },
          ],
        },
        {
          matcher: {
            id: 'byName',
            options: 'pod',
          },
          properties: [
            {
              id: 'links',
              value: [
                {
                  title: 'Go to pod',
                  url: '/a/grafana-k8s-app/navigation/namespace/${__data.fields.encoded_cluster}/${__data.fields.namespace}/${__data.fields.workload_type}/${__data.fields.workload}/${__data.fields.pod}${__url.params:exclude:var-loki,var-severity,var-cluster,var-datasource,var-namespace}',
                },
              ],
            },
          ],
        },
        {
          matcher: {
            id: 'byName',
            options: 'container',
          },
          properties: [
            {
              id: 'links',
              value: [
                {
                  title: 'Go to container',
                  url: '/a/grafana-k8s-app/navigation/namespace/${__data.fields.encoded_cluster}/${__data.fields.namespace}/${__data.fields.workload_type}/${__data.fields.workload}/${__data.fields.pod}/${__data.fields.container}${__url.params:exclude:var-loki,var-severity,var-cluster,var-datasource,var-namespace}',
                },
              ],
            },
          ],
        },
        {
          matcher: {
            id: 'byName',
            options: 'workload',
          },
          properties: [
            {
              id: 'links',
              value: [
                {
                  title: 'Go to ${__data.fields.workload_type}',
                  url: '/a/grafana-k8s-app/navigation/namespace/${__data.fields.encoded_cluster}/${__data.fields.namespace}/${__data.fields.workload_type}/${__data.fields.workload}${__url.params:exclude:var-loki,var-severity,var-cluster,var-datasource,var-namespace}',
                },
              ],
            },
          ],
        },
      ],
    },
  }).setData(panel.runner);
};
