import { config } from '@grafana/runtime';
import { IconButton, LinkButton } from '@grafana/ui';
import useRudderStack from 'hooks/useRudderstack';
import { compileLogsQuery, compileOTelLogsQuery } from 'queries';
import React, { useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { RudderstackEvents } from 'enums';
import { Pod, UsageCostErrors } from 'types';
import { getPodhealthStatus, podHealthToColorText } from '../podHealthUtils';
import useDatasourceStore from 'store/datasource';
import { lokiSelector } from 'store/selectors/datasource';
import { copyContent, encodeUrlString, QueryCollection } from 'helpers/helpers';
import useTimeRangeStore from 'store/timeRange';
import { shallow } from 'zustand/shallow';
import { Location } from 'history';
import CopyObjectName from 'components/Table/CopyObjectName';
import QueryParamsLink from 'components/QueryParamsLink/QueryParamsLink';
import { getAllColumns } from 'helpers/usageCostHelpers';
import alertColumn from 'components/Table/alertColumn';
import { PLUGIN_ROOT_URL } from '../../../constants';

interface PodLinkProps {
  pod: string;
  namespace: string;
  cluster: string;
}

export const PodLogLink = ({ pod, namespace, cluster }: PodLinkProps) => {
  const lokiName = useDatasourceStore(lokiSelector);
  const trackRudderStackEvent = useRudderStack();
  const [range, relativeRange] = useTimeRangeStore((state) => [state.range, state.relativeRange], shallow);
  const exploreRange = {
    from: relativeRange?.from || range.from.valueOf().toString(),
    to: relativeRange?.to || range.to.valueOf().toString(),
  };

  const queries = new QueryCollection(
    lokiName,
    exploreRange,
    false,
    {
      primary: compileOTelLogsQuery(cluster, namespace, pod),
      backup: compileLogsQuery(cluster, namespace, pod),
    },
    []
  );

  return (
    <LinkButton
      style={{ marginRight: '10px' }}
      variant="secondary"
      target="_blank"
      size="sm"
      icon="external-link-alt"
      href={queries.toExploreUrl()}
      disabled={!pod}
      onClick={() => trackRudderStackEvent(RudderstackEvents.ExploreLogs, {})}
    >
      Explore pod logs
    </LinkButton>
  );
};

export const CopyPodName = ({ podName }: { podName: string }) => {
  const [copied, setCopied] = useState(false);

  return (
    <IconButton
      aria-label="Copy content"
      size="sm"
      name={copied ? 'check' : 'clipboard-alt'}
      variant="secondary"
      onClick={() => copyContent(podName, setCopied)}
      style={{ marginRight: 16 }}
    />
  );
};

export const workloadDetailColumns = (
  styles: any,
  rootPath: string,
  location: Location,
  costView: boolean,
  errors: UsageCostErrors,
  hasDateChanged: boolean,
  prometheusName: string,
  usageLoading?: boolean,
  costLoading?: boolean
): Array<TableColumn<Pod>> => {
  const columns = [
    {
      name: 'Pod Name',
      selector: (row: Pod) => row.pod,
      // eslint-disable-next-line react/display-name
      cell: (row: Pod) => (
        <CopyObjectName
          link={`${PLUGIN_ROOT_URL}/navigation/namespace/${encodeUrlString(row.cluster)}/${row.namespace}/${
            row.workload_type
          }/${row.workload}/${row.pod}`}
          value={row.pod}
        />
      ),

      sortable: true,
      sortFunction: (a: Pod, b: Pod) => a.pod.localeCompare(b.pod, undefined, { numeric: true }),
    },
    ...(!costView
      ? [
          {
            name: 'Node',
            selector: (row: Pod) => row.node,
            // eslint-disable-next-line react/display-name
            cell: (row: Pod) => (
              <QueryParamsLink
                className={styles.link}
                to={`/a/${rootPath}/navigation/nodes/${encodeUrlString(row.cluster)}/${row.node}`}
                label={row.node}
              />
            ),
            sortable: true,
            sortFunction: (a: Pod, b: Pod) => a.node.localeCompare(b.node, undefined, { numeric: true }),
          },
          {
            name: 'Status',
            selector: (row: Pod) => row.phase,
            width: '150px',
            sortable: true,
            // eslint-disable-next-line react/display-name
            cell: (row: Pod) => (
              <>
                <div
                  style={{
                    color: podHealthToColorText(config)[getPodhealthStatus(row)],
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  {row.phase}
                  {row?.waiting_reason && <span style={{ fontSize: 11, marginTop: 2 }}>({row.waiting_reason})</span>}
                </div>
              </>
            ),
          },
        ]
      : []),
  ];

  const { currentColumns, extraColumns } = getAllColumns<Pod>(
    costView,
    columns,
    [],
    [
      {
        name: 'Logs',
        selector: (row: Pod) => row.pod,
        // eslint-disable-next-line react/display-name
        cell: (row: Pod) => <PodLogLink pod={row.pod} namespace={row.namespace} cluster={row.cluster} />,
      },
      alertColumn<Pod>({ hasDateChanged, prometheusName }),
    ],
    errors,
    usageLoading,
    costLoading
  );
  return [...currentColumns, ...extraColumns];
};
