import { ThresholdsMode } from '@grafana/data';
import { PanelBuilders } from '@grafana/scenes';
import {
  AxisColorMode,
  AxisPlacement,
  BigValueColorMode,
  BigValueGraphMode,
  BigValueJustifyMode,
  BigValueTextMode,
  GraphDrawStyle,
  GraphGradientMode,
  GraphThresholdsStyleMode,
  LegendDisplayMode,
  LineInterpolation,
  ScaleDistribution,
  SortOrder,
  StackingMode,
  TableCellDisplayMode,
  TableCellHeight,
  TooltipDisplayMode,
  VisibilityMode,
  VizOrientation,
} from '@grafana/schema';
import { PanelConfig } from 'components/scenes/CostsOverviewScene/panelConfig';
import jsonToPanelBuilders from 'helpers/jsonToPanelBuilders';
import { GaguePanelType, StatPanelType, TablePanelType, TimeSeriesPanelType } from 'types';

export const coresConfig = (panel: PanelConfig) => {
  const panelBuilder = PanelBuilders.stat();

  return jsonToPanelBuilders<StatPanelType>(panelBuilder, {
    title: panel.title,
    description: panel.description,
    $data: panel.runner,
    options: {
      reduceOptions: {
        values: false,
        calcs: ['lastNotNull'],
        fields: '',
      },
      orientation: VizOrientation.Auto,
      textMode: BigValueTextMode.Value,
      colorMode: BigValueColorMode.Value,
      graphMode: BigValueGraphMode.None,
      justifyMode: BigValueJustifyMode.Auto,
      text: {
        valueSize: panel.textSize ?? 30,
      },
    },
    fieldConfig: {
      defaults: {
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'text',
              value: 0,
            },
          ],
        },
        color: {
          fixedColor: 'text',
          mode: 'thresholds',
        },
        unit: panel?.unit ?? 'cores',
      },
      overrides: [],
    },
  }).setData(panel.runner);
};

export const gaugeConfig = (panel: PanelConfig) => {
  const panelBuilder = PanelBuilders.gauge();

  return jsonToPanelBuilders<GaguePanelType>(panelBuilder, {
    title: panel.title,
    description: panel.description,
    $data: panel.runner,
    options: {
      reduceOptions: {
        values: false,
        calcs: ['lastNotNull'],
        fields: '',
      },
      orientation: VizOrientation.Auto,
      showThresholdLabels: false,
      showThresholdMarkers: true,
      minVizWidth: 75,
      minVizHeight: 75,
    },
    fieldConfig: {
      defaults: {
        custom: {
          neutral: 0,
        },
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'red',
              value: 0,
            },
            {
              color: 'red',
              value: -1.1,
            },
            {
              color: 'green',
              value: -1,
            },
            {
              color: 'green',
              value: -1,
            },
            {
              color: 'green',
              value: 1,
            },
            {
              color: 'red',
              value: 1.1,
            },
            {
              color: 'red',
              value: 10,
            },
          ],
        },
        color: {
          fixedColor: 'text',
          mode: 'thresholds',
        },
        decimals: 3,
        max: 10,
        min: -10,
        unit: panel?.unit ?? 'cores',
      },
      overrides: [],
    },
  }).setData(panel.runner);
};

export const thresholdGraphConfig = (panel: PanelConfig) => {
  const panelBuilder = PanelBuilders.timeseries();
  return jsonToPanelBuilders<TimeSeriesPanelType>(panelBuilder, {
    title: panel.title,
    description: panel.description,
    $data: panel.runner,
    options: {
      tooltip: {
        mode: TooltipDisplayMode.Single,
        sort: SortOrder.None,
      },
      legend: {
        showLegend: true,
        displayMode: LegendDisplayMode.Table,
        placement: 'bottom',
        calcs: ['min', 'mean', 'max'],
      },
    },
    fieldConfig: {
      defaults: {
        custom: {
          drawStyle: GraphDrawStyle.Line,
          lineInterpolation: LineInterpolation.Smooth,
          barAlignment: 0,
          lineWidth: 1,
          fillOpacity: 0,
          gradientMode: GraphGradientMode.None,
          spanNulls: false,
          insertNulls: false,
          showPoints: VisibilityMode.Never,
          pointSize: 5,
          stacking: {
            mode: StackingMode.None,
            group: 'A',
          },
          axisPlacement: AxisPlacement.Auto,
          axisLabel: '',
          axisColorMode: AxisColorMode.Text,
          axisBorderShow: false,
          scaleDistribution: {
            type: ScaleDistribution.Linear,
          },
          axisCenteredZero: false,
          hideFrom: {
            tooltip: false,
            viz: false,
            legend: false,
          },
          thresholdsStyle: {
            mode: GraphThresholdsStyleMode.DashedAndArea,
          },
          lineStyle: {
            fill: 'solid',
          },
        },
        color: {
          mode: 'thresholds',
        },
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Percentage,
          steps: [
            {
              color: 'green',
              value: 0,
            },
            {
              color: 'red',
              value: 25,
            },
          ],
        },
        max: 1,
        min: 0,
        unit: 'percentunit',
      },
      overrides: [],
    },
  }).setData(panel.runner);
};

export const boolConfig = (panel: PanelConfig) => {
  const panelBuilder = PanelBuilders.stat();

  return jsonToPanelBuilders<StatPanelType>(panelBuilder, {
    title: panel.title,
    description: panel.description,
    $data: panel.runner,
    options: {
      reduceOptions: {
        values: false,
        calcs: ['first'],
        fields: '/^Time$/',
      },
      orientation: VizOrientation.Auto,
      textMode: BigValueTextMode.Value,
      colorMode: BigValueColorMode.Value,
      graphMode: BigValueGraphMode.Area,
      justifyMode: BigValueJustifyMode.Center,
      text: {
        valueSize: panel.textSize ?? 30,
      },
    },
    fieldConfig: {
      defaults: {
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'text',
              value: 0,
            },
            {
              color: 'red',
              value: 1,
            },
          ],
        },
        color: {
          mode: 'thresholds',
        },
        unit: 'bool',
      },
      overrides: [],
    },
  }).setData(panel.runner);
};

export const restartsGraphConfig = (panel: PanelConfig) => {
  const panelBuilder = PanelBuilders.timeseries();
  return jsonToPanelBuilders<TimeSeriesPanelType>(panelBuilder, {
    title: panel.title,
    description: panel.description,
    $data: panel.runner,
    options: {
      tooltip: {
        mode: TooltipDisplayMode.Multi,
        sort: SortOrder.None,
      },
      legend: {
        showLegend: false,
        displayMode: LegendDisplayMode.Table,
        placement: 'bottom',
        calcs: ['last'],
      },
    },
    fieldConfig: {
      defaults: {
        custom: {
          drawStyle: GraphDrawStyle.Bars,
          lineInterpolation: LineInterpolation.Linear,
          barAlignment: 0,
          lineWidth: 0,
          fillOpacity: 60,
          gradientMode: GraphGradientMode.Hue,
          spanNulls: false,
          insertNulls: false,
          showPoints: VisibilityMode.Never,
          pointSize: 5,
          stacking: {
            mode: StackingMode.None,
            group: 'A',
          },
          axisPlacement: AxisPlacement.Auto,
          axisLabel: '',
          axisColorMode: AxisColorMode.Text,
          axisBorderShow: false,
          scaleDistribution: {
            type: ScaleDistribution.Linear,
          },
          axisCenteredZero: false,
          hideFrom: {
            tooltip: false,
            viz: false,
            legend: false,
          },
          thresholdsStyle: {
            mode: GraphThresholdsStyleMode.Off,
          },
          axisSoftMax: 1,
          lineStyle: {
            fill: 'solid',
          },
        },
        color: {
          mode: 'fixed',
          fixedColor: 'red',
        },
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
            {
              color: 'red',
              value: 1,
            },
          ],
        },
        decimals: 0,
      },
      overrides: [],
    },
  }).setData(panel.runner);
};

export const restartsTableConfig = (panel: PanelConfig) => {
  const panelBuilder = PanelBuilders.table();

  return jsonToPanelBuilders<TablePanelType>(panelBuilder, {
    title: panel.title,
    description: panel.description,
    $data: panel.runner,
    options: {
      showHeader: true,
      cellHeight: TableCellHeight.Sm,
      footer: {
        show: false,
        reducer: ['sum'],
        countRows: false,
        fields: [''],
      },
    },
    fieldConfig: {
      defaults: {
        custom: {
          align: 'auto',
          cellOptions: {
            type: TableCellDisplayMode.Auto,
          },
          inspect: false,
        },
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
            {
              color: 'red',
              value: 0.00001,
            },
          ],
        },
        color: {
          mode: 'thresholds',
        },
      },
      overrides: [],
    },
  }).setData(panel.runner);
};

export const latestTerminatedConfig = (panel: PanelConfig) => {
  const panelBuilder = PanelBuilders.timeseries();
  return jsonToPanelBuilders<TimeSeriesPanelType>(panelBuilder, {
    title: panel.title,
    description: panel.description,
    $data: panel.runner,
    options: {
      tooltip: {
        mode: TooltipDisplayMode.Single,
        sort: SortOrder.None,
      },
      legend: {
        showLegend: true,
        displayMode: LegendDisplayMode.List,
        placement: 'bottom',
        calcs: [],
      },
    },
    fieldConfig: {
      defaults: {
        custom: {
          drawStyle: GraphDrawStyle.Line,
          lineInterpolation: LineInterpolation.Smooth,
          barAlignment: 0,
          lineWidth: 0,
          fillOpacity: 60,
          gradientMode: GraphGradientMode.Hue,
          spanNulls: false,
          insertNulls: false,
          showPoints: VisibilityMode.Never,
          pointSize: 5,
          stacking: {
            mode: StackingMode.None,
            group: 'A',
          },
          axisPlacement: AxisPlacement.Auto,
          axisLabel: '',
          axisColorMode: AxisColorMode.Text,
          axisBorderShow: false,
          scaleDistribution: {
            type: ScaleDistribution.Linear,
          },
          axisCenteredZero: false,
          hideFrom: {
            tooltip: false,
            viz: false,
            legend: false,
          },
          thresholdsStyle: {
            mode: GraphThresholdsStyleMode.Off,
          },
          axisGridShow: true,
          axisSoftMax: 1,
        },
        color: {
          mode: 'fixed',
          fixedColor: 'red',
        },
        mappings: [],
        thresholds: {
          mode: ThresholdsMode.Absolute,
          steps: [
            {
              color: 'green',
              value: 0,
            },
          ],
        },
        decimals: 0,
      },
      overrides: [],
    },
  }).setData(panel.runner);
};
