import get from 'lodash/get';
import { GeneralQueryResult, Labels, NodeInfo, NodePods, ResourceResult } from 'types';

export const getNodeInfo = (nodeData: Array<GeneralQueryResult<NodeInfo>>) => {
  const { __name__, ...rest } = get(nodeData, [0, 'metric'], { __name__: '', cluster: '', asserts_env: '' });
  return rest;
};

export const getNodeLabels = (labelsData: Array<GeneralQueryResult<Labels>>) => {
  // Only pick labels and not the rest of the fields
  const { __name__, cluster, instance, job, node, container, name, namespace, pod, pod_template_hash, ...rest } = get(
    labelsData,
    [0, 'metric'],
    {
      __name__: '',
      job: '',
      cluster: '',
      instance: '',
      node: '',
      container: '',
      name: '',
      namespace: '',
      pod: '',
      pod_template_hash: '',
    }
  );

  return rest;
};

export const getResourceData = (data: Array<GeneralQueryResult<ResourceResult>>) => {
  const keys = ['cpu', 'ephemeral_storage', 'memory', 'pods'];
  const resourceData = {
    pods: 0,
    cpu: 0,
    ephemeral_storage: 0,
    memory: 0,
  };

  data?.forEach?.(({ metric, value }) => {
    if (keys.includes(metric.resource)) {
      (resourceData as any)[metric.resource] = parseFloat(value[1]);
    }
  });

  return resourceData;
};

export const parseNodePods = (data: Array<GeneralQueryResult<NodePods>>) => {
  return data?.reduce<Record<string, number>>((acc, { value, metric }) => {
    acc[metric.node] = parseInt(value[1], 10);
    return acc;
  }, {});
};
